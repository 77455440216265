import { useState } from 'react'
import { Flex, Select, Typography } from 'antd'
import { DataSourceType } from 'types/chart.types'

export const DATA_SOURCE_OPTIONS = [
  { label: 'QuickBooks Online', value: 'QB' },
  { label: 'Shopify (Accrual Only)', value: 'SP' },
]

type SelectDataSourceProps = {
  value: DataSourceType[]
  onChange: (value: DataSourceType[]) => void
}

export function SelectDataSource(props: SelectDataSourceProps) {
  const { value, onChange } = props
  const [open, setOpen] = useState(false)

  const onDataSourceChange = (value: DataSourceType[]) => {
    const isOptionsValid = value.length >= 1
    isOptionsValid && onChange(value)
  }

  return (
    <Flex align="center">
      <Typography.Text>Display Connect Data From: </Typography.Text>
      &nbsp;&nbsp;
      <Select
        mode="multiple"
        dropdownStyle={{ minWidth: 400 }}
        style={{ minWidth: 280 }}
        optionFilterProp="label"
        open={open}
        onDropdownVisibleChange={setOpen}
        value={value}
        onChange={onDataSourceChange}
        options={DATA_SOURCE_OPTIONS}
        maxTagCount="responsive"
      />
    </Flex>
  )
}
