import ReactGa from 'react-ga4'

export const analytics = {
  init: (id: string) => {
    ReactGa.initialize(id)
  },
  event: {
    signIn: () => ReactGa.event({ action: 'signIn', category: 'user' }),
    signInGoogle: () => ReactGa.event({ action: 'signInGoogle', category: 'user' }),
    signUp: () => ReactGa.event({ action: 'signUp', category: 'user' }),
    signUpGoogle: () => ReactGa.event({ action: 'signUpGoogle', category: 'user' }),
    compliance: {
      start: () => ReactGa.event({ action: 'compliance_start', category: 'compliance' }),
      error: () => ReactGa.event({ action: 'compliance_error', category: 'compliance' }),
    },
    reconciliation: {
      start: () => ReactGa.event({ action: 'reconciliation_start', category: 'reconciliation' }),
      limit: () => ReactGa.event({ action: 'reconciliation_limit_5000', category: 'reconciliation' }),
      error: () => ReactGa.event({ action: 'reconciliation_error', category: 'reconciliation' }),
    },
    plaid: {
      connect: () => ReactGa.event({ action: 'plaid_connect', category: 'plaid' }),
      error: () => ReactGa.event({ action: 'plaid_error', category: 'plaid' }),
    }
  }
}