import { MouseEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { useProjectList } from 'hooks/useProjects'
import { useProject } from 'hooks/useProject'
import { IProject, ProjectType } from 'types/project.types'

type DeletePayload = {
  event: MouseEvent
  id: IProject['id']
}

const initialPaginationState = { current: 1, pageSize: 10 }

export function TemplatesPage() {
  const navigate = useNavigate()
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState)
  const { remove, removeLoading } = useProject()

  const {
    projectList: templates,
    refetch: refetchTemplates,
    totalCount,
    isLoading,
    isValidating,
  } = useProjectList({ pagination, extraParams: { type: ProjectType.TEMPLATE } })

  const onDeleteClick = ({ event, id }: DeletePayload) => {
    event.stopPropagation()

    const onRemoveSuccess = () => {
      refetchTemplates()
    }

    remove({ id }, onRemoveSuccess)
  }

  const loading = isLoading || removeLoading || isValidating
  const columns = getColumns(onDeleteClick)

  useEffect(() => {
    if (templates) setPagination((prev: any) => ({ ...prev, total: totalCount }))
  }, [templates])

  return (
    <Table
      loading={loading}
      dataSource={templates}
      columns={columns}
      pagination={pagination}
      rowKey="id"
      onRow={(record) => {
        return {
          onClick: () => {
            navigate(`/workflow/projects/${record.id}`)
          },
        }
      }}
    />
  )
}

const getColumns = (onDeleteClick: ({ event, id }: DeletePayload) => void): ColumnsType<any> => [
  { title: 'Title', dataIndex: 'name', key: 'name' },
  {
    title: 'Action',
    key: 'action',
    width: 50,
    render: (project: IProject) => (
      <Button onClick={(event) => onDeleteClick({ event, id: project.id })} type="primary" danger>
        Delete
      </Button>
    ),
  },
]
