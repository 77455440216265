import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useCompanies } from 'hooks/useCompanies'
import { useCompanyMembers } from 'hooks/useCompanyMembers'
import { useProject } from 'hooks/useProject'
import { IProject, ProjectRecurring, ProjectRecurringFrequency, ProjectStatus } from 'types/project.types'
import {
  PROJECT_RECURRING_FREQUENCY_OPTIONS,
  PROJECT_RECURRING_OPTIONS,
  PROJECT_STATUS_OPTIONS,
} from 'constants/options'

interface Props {
  open: boolean
  onClose: () => void
  initialValues?: IProject & { recurring: ProjectRecurring; accounting_period: ProjectRecurringFrequency }
  isEditMode?: boolean
  onSuccess?: () => void
}

export function AddProjectModal(props: Props) {
  const { open, onClose, initialValues, isEditMode, onSuccess } = props
  const [companyMembersLoaded, setCompanyMembersLoaded] = useState(false)
  const [form] = useForm()

  const companyField = useWatch('company', form)
  const recurringField = useWatch('recurring', form)

  const defaultValues = useMemo(
    () => ({
      recurring: ProjectRecurring.Yes,
      status: ProjectStatus.New,
      accounting_period: ProjectRecurringFrequency.Weekly,
    }),
    [],
  )

  const onCompanyMembersSuccess = () => {
    setCompanyMembersLoaded(true)
  }

  const companies = useCompanies()
  const { companyMembers, isLoading: companyMembersLoading } = useCompanyMembers(companyField, onCompanyMembersSuccess)
  const { create, createLoading, update, updateLoading } = useProject()

  const companyOptions = useMemo(
    () => companies.data?.map((company) => ({ label: company.alias || company.name, value: company.id })),
    [companies.data],
  )
  const asigneeOptions = useMemo(
    () => companyMembers?.map((member: any) => ({ label: `${member.firstName} ${member.lastName}`, value: member.id })),
    [companyMembers],
  )

  const recurringFrequencyFieldDisabled = recurringField !== ProjectRecurring.Yes
  const assigneeFieldDisabled = companyMembersLoading || !asigneeOptions?.length || !companyMembersLoaded

  // reset asignee field value on client/company field change
  useEffect(() => {
    companyField && form.setFieldValue('user', undefined)
  }, [companyField])

  // reset recurring frequency field error and value on recurring field change
  useEffect(() => {
    if (recurringField === ProjectRecurring.No) {
      form.setFieldValue('accounting_period', undefined)
      form.validateFields(['accounting_period'])
    }

    if (recurringField === ProjectRecurring.Yes) {
      form.setFieldValue('accounting_period', ProjectRecurringFrequency.Weekly)
    }
  }, [recurringField])

  // set initial values when edit mode
  useEffect(() => {
    const shouldExit = !open || !isEditMode || !initialValues

    if (shouldExit) {
      return
    }

    form.setFieldsValue({
      name: initialValues.name,
      dueDate: initialValues.dueDate ? dayjs(initialValues.dueDate) : null,
      startDate: initialValues.startDate ? dayjs(initialValues.startDate) : null,
      recurring: initialValues.recurring,
      accounting_period: initialValues.accounting_period,
      status: initialValues.status,
      company: initialValues.company?.id,
      user: companyMembersLoaded ? initialValues.user?.id : undefined,
    })
  }, [initialValues, open, companyMembersLoaded])

  const onModalClose = () => {
    setCompanyMembersLoaded(false)
    form.resetFields()
    onClose()
  }

  const onProjectAdd = (values: any) => {
    const onActionSuccess = () => {
      onModalClose()
      onSuccess?.()
    }

    if (isEditMode) {
      update({ ...values, id: initialValues?.id }, onActionSuccess)
    } else {
      create(values, onActionSuccess)
    }
  }

  return (
    <Modal
      width={720}
      title="Add Project"
      open={open}
      onCancel={onModalClose}
      cancelText="Cancel"
      okText={isEditMode ? 'Update' : 'Create'}
      okButtonProps={{ form: 'project-form', htmlType: 'submit' }}
      confirmLoading={createLoading || updateLoading}
    >
      <Form form={form} onFinish={onProjectAdd} layout="vertical" id="project-form" initialValues={defaultValues}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Project Name"
              name="name"
              rules={[{ required: true, message: 'Please enter project name!' }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Client" name="company">
              <Select
                loading={companies.isLoading}
                disabled={companies.isLoading}
                placeholder="Select an option"
                options={companyOptions}
                showSearch={true}
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Start Date" name="startDate" normalize={(value) => dayjs(value)}>
              <DatePicker placeholder="Optional" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Due Date" name="dueDate" normalize={(value) => dayjs(value)}>
              <DatePicker placeholder="Optional" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Recurring" name="recurring">
              <Select placeholder="Select an option" options={PROJECT_RECURRING_OPTIONS} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Recurring Frequency" name="accounting_period">
              <Select
                placeholder="Select an option"
                options={PROJECT_RECURRING_FREQUENCY_OPTIONS}
                disabled={recurringFrequencyFieldDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Assignee" name="user">
              <Select
                placeholder="Assignee"
                loading={companyMembersLoading}
                disabled={assigneeFieldDisabled}
                options={asigneeOptions}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Status" name="status">
              <Select placeholder="Select an option" options={PROJECT_STATUS_OPTIONS} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
