import { useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Col, Divider, Flex, Form, Input, Row, Typography, notification } from 'antd'
import { Helmet } from 'react-helmet'

import { AppFooter } from '../../app/AppFooter'
import { AuthPage } from './components/AuthPage'
import { Logo } from '../../components/Logo'

import { resetPassword } from 'utils/firebase'
import { getErrorMsg } from 'utils/geterror'
import { rules } from 'utils/form'

const { Title } = Typography

export function ResetPasswordPage() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const oobCode = searchParams.get('oobCode')

  const [loading, setLoading] = useState(false)

  if (mode !== 'resetPassword') return <Navigate to="/" />
  if (!oobCode) return <Navigate to="/" />

  const onCancel = () => {
    navigate('/auth/signin')
  }

  const onSubmit = (values: any) => {
    setLoading(true)
    resetPassword(oobCode, values.password)
      .then(() => {
        notification.success({ message: 'Success', description: "New password set. You're now signed in." })
        navigate('/')
      })
      .catch((error) => notification.error(getErrorMsg(error)))
      .finally(() => setLoading(false))
  }

  return (
    <AuthPage>
      <Helmet>
        <title>Equility - Verify your email address</title>
      </Helmet>
      <Row style={contentStyle}>
        <Col span={24}>
          <Flex vertical justify="center" align="center">
            <Title level={1}>
              <Logo />
            </Title>
            <Flex vertical gap={10} align="center">
              <Title level={3} style={titleStyle}>
                Reset your password
              </Title>
              <Form name="reset" style={formStyle} onFinish={onSubmit}>
                <Form.Item name="password" rules={rules.password}>
                  <Input type="password" placeholder="Enter your new password" />
                </Form.Item>
              </Form>
              <Flex gap={10}>
                <Button type="primary" htmlType="submit" form="reset" loading={loading}>
                  Submit
                </Button>
                <Button danger onClick={onCancel}>
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
        </Col>
        <Col span={24}>
          <AppFooter />
        </Col>
      </Row>
    </AuthPage>
  )
}

const contentStyle = {
  width: 800,
}

const titleStyle: React.CSSProperties = {
  width: 500,
  marginTop: 0,
  marginBottom: 10,
  textAlign: 'center',
}

const formStyle: React.CSSProperties = {
  width: 500,
}
