import useSWR from 'swr'

import { useCompanyStore } from 'store/company.store'

import { api } from 'utils/axios'
import type { IQboVendor } from 'types/company.types'

export function useVendors() {
  const companyId = useCompanyStore((state) => state.id)
  const vendorsKey = companyId ? ['accounting/vendors', companyId] : null
  const customersKey = companyId ? ['accounting/customers', companyId] : null

  const vendors = useSWR<IQboVendor[]>(vendorsKey, () => fetch(vendorsKey))
  const customers = useSWR<IQboVendor[]>(customersKey, () => fetch(customersKey))

  return {
    vendors: vendors.data || [],
    customers: customers.data || [],
    isLoading: vendors.isLoading || customers.isLoading,
    error: vendors.error || customers.error,
  }
}

const fetch = (key: (string | number)[] | null) => {
  if (!key || !key[1]) return Promise.resolve([])
  const [path, companyId] = key
  return api.get('/' + path, { params: { companyId } })
    .then((res) => res.data)
}