import { useState } from 'react'
import { Button, Flex, Tabs, Input, Tooltip } from 'antd'
import { Tab } from 'rc-tabs/lib/interface'
import { Page } from 'components/Page'
import AcceptedMembers from './AcceptedMembers'
import InvitedMembers from './InvitedMembers'
import InviteTeamMateModal from 'components/InviteTeammateModal'
import { useUser } from 'hooks/useUser'
import { IAcceptedMember, RoleType } from 'types/user.types'
import { useTeam, useTeamInvites, useTeamRescindInvite, useTeamResendInvite } from 'hooks/useTeam'

const { Search } = Input

export function TeamPage() {
  const [activeTab, setActiveTab] = useState('1')
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const { user } = useUser()
  const {
    data: acceptedMembers,
    isLoading: acceptedMembersLoading,
    deleteLoading,
    deleteMember,
    updateRole,
    updateRoleLoading,
    updateCompanyAccess,
    updateCompanyAccessLoading,
  } = useTeam()

  const { resendInvite, resendInviteLoading } = useTeamResendInvite()
  const { rescindInvite, rescindInviteLoading } = useTeamRescindInvite()

  const { data: invitedMembers, isLoading: invitedMembersLoading, refetch: refetchInvitedMembers } = useTeamInvites()
  const isAdminUser = user?.member?.role === RoleType.Admin

  const filteredAcceptedMembers = acceptedMembers?.filter((item: IAcceptedMember) => {
    const searchValue = searchQuery.toLowerCase()
    return (
      item.user?.firstName.toLowerCase().includes(searchValue) ||
      item.user?.lastName.toLowerCase().includes(searchValue) ||
      item.user?.email.toLowerCase().includes(searchValue)
    )
  })

  const filteredInvitedMembers = invitedMembers?.filter((item: any) => {
    const searchValue = searchQuery.toLowerCase()
    return item?.email.toLowerCase().includes(searchValue)
  })

  const items: Tab[] = [
    {
      key: '1',
      label: 'Team members',
      children: (
        <AcceptedMembers
          data={filteredAcceptedMembers}
          loading={acceptedMembersLoading}
          isAdminUser={isAdminUser}
          deleteLoading={deleteLoading}
          deleteMember={deleteMember}
          updateRole={updateRole}
          updateRoleLoading={updateRoleLoading}
          updateCompanyAccess={updateCompanyAccess}
          updateCompanyAccessLoading={updateCompanyAccessLoading || acceptedMembersLoading}
        />
      ),
    },
    {
      key: '2',
      label: 'Invited',
      children: (
        <InvitedMembers
          data={filteredInvitedMembers}
          loading={invitedMembersLoading || resendInviteLoading || rescindInviteLoading}
          resendInvite={resendInvite}
          rescindInvite={(params) => rescindInvite(params, { onSuccess: refetchInvitedMembers })}
        />
      ),
    },
  ]

  const tabItems = isAdminUser ? items : [items[0]]

  const onInviteSuccess = () => {
    setActiveTab('2')
    refetchInvitedMembers()
  }

  return (
    <Page title="Team Management">
      <Flex vertical gap={10}>
        <Flex style={headerStyle} gap={20} align="center" justify="space-between">
          <Search style={searchStyle} placeholder="Search" onChange={(e) => setSearchQuery(e.target.value)} />

          <Tooltip title={!isAdminUser && "This functionality is only available to users with an 'Admin' role"}>
            <Button type="primary" onClick={() => setInviteModalVisible(true)} disabled={!isAdminUser}>
              Invite a team member
            </Button>
          </Tooltip>
        </Flex>
        <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />
        <InviteTeamMateModal
          open={inviteModalVisible}
          onClose={() => setInviteModalVisible(false)}
          onSuccess={onInviteSuccess}
        />
      </Flex>
    </Page>
  )
}

const headerStyle: React.CSSProperties = {
  marginTop: 10,
}

const searchStyle: React.CSSProperties = {
  width: 300,
}
