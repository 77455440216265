import { api } from 'utils/axios'
import useSWRMutation from 'swr/mutation'

export function useComments() {
  const { trigger: createComment, isMutating: createLoading } = useSWRMutation('comments', createFetcher)

  return {
    createComment,
    createLoading,
  }
}

interface ICreateData {
  arg: { entityId: number; entityType: string; body: string; files: string[] }
}

const createFetcher = async (url: string, { arg }: ICreateData) => {
  const res = await api.post(url, arg)
  return res.data
}
