import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Card, Col, Descriptions, Divider, Flex, Input } from 'antd'
import { Row, Skeleton, Space, Tag, Typography, notification, theme } from 'antd'

import { HelpTooltip } from 'components/HelpTooltip'
import { ShowCase } from './components/ShowCase'

import { api } from 'utils/axios'
import { useUser } from 'hooks/useUser'
import { useRole } from 'hooks/useRole'
import { useStripe } from 'hooks/useStripe'
import { formatDate } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import { getErrorMsg } from 'utils/geterror'
import { AccessLevel } from 'types/subscription.types'
import { RoleType } from 'types/user.types'

const { Text } = Typography

export function BillingPage() {
  const { isLoading, subscription, portalUrl, revalidate } = useStripe()
  const { isAllowed, isLoading: loading } = useRole(RoleType.Owner)
  const { user } = useUser()

  const [code, setCode] = useState('')
  const [wait, setWait] = useState(false)

  if (isLoading || loading)
    return (
      <Card>
        <Skeleton active />
      </Card>
    )

  if (!isAllowed)
    return (
      <Alert
        message="You are not allowed to view this page"
        description="Only the account owner can view this page"
        type="error"
        showIcon
      />
    )

  if (!subscription) return <ShowCase />

  const accessLevel = (subscription as any)?.plan?.metadata?.accessLevel || 0
  const unitPrice = (subscription as any).plan.amount / 100

  const status =
    subscription.status === 'active' ? (
      <Tag color="success">active</Tag>
    ) : subscription.status === 'trialing' ? (
      <Tag color="warning">trialing</Tag>
    ) : (
      <Tag color="error">inactive</Tag>
    )

  const percentOff = subscription.discounts?.reduce((acc, discount) => {
    return acc += discount.coupon.percent_off || 0
  }, 0)
  const amountOff = subscription.discounts?.reduce((acc, discount) => {
    return acc += discount.coupon.amount_off || 0
  }, 0)

  const totalOff = percentOff ? (unitPrice * percentOff) / 100 : amountOff / 100
  const subscriptionPrice = unitPrice * subscription.quantity - totalOff

  const isAppSumo = user?.source === 'appsumo'
    || subscription.meta['appsumo'] === 'true'
    || subscription.meta['source'] === 'appsumo'

  // console.log(subscription.meta['appsumo'], subscription.meta['source'])
  // console.log('subscription', subscription)
  // console.log('subscription 1', unitPrice * subscription.quantity)
  // console.log('subscription 2', percentOff, amountOff, totalOff, subscriptionPrice)

  const onSubmitCoupon = () => {
    if (!code) return
    setWait(true)
    console.log('Applying coupon', code)
    api.post('/user/subscription/coupon', { code })
      .then(() => {
        revalidate()
        setCode('')
        notification.success({ 
          message: 'Success',
          description: 'Coupon applied successfully'
        })
      })
      .catch((error) => notification.error(getErrorMsg(error)))
      .finally(() => setWait(false))
  }

  return (
    <Flex vertical gap={10}>
      {subscription?.status === 'trialing' && <TrialAlert nextChargeDate={subscription.nextChargeDate} />}
      {isAppSumo && <AppSumoAlert />}
      <Row gutter={16}>
        <Col span={14}>
          <Divider orientation="left">Subscription</Divider>
          <Card title="">
            <Descriptions>
              <Descriptions.Item label={items.status} span={3}>
                {status}
              </Descriptions.Item>
              <Descriptions.Item label={items.tier} span={3}>
                {AccessLevel[accessLevel || 0]}
              </Descriptions.Item>
              <Descriptions.Item label={items.pricePerCompany} span={3}>
                {toCurrency(unitPrice)}
              </Descriptions.Item>
              <Descriptions.Item label={items.availableCompanies} span={3}>
                {subscription.quantity}
              </Descriptions.Item>
              <Descriptions.Item label={items.subscriptionPrice} span={3}>
                {toCurrency(Math.max(subscriptionPrice, 0))}
              </Descriptions.Item>
              <Descriptions.Item label={items.nextChargeDate} span={3}>
                {formatDate(subscription.nextChargeDate)}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={10}>
          <Divider orientation="left">Add coupon</Divider>
          <Card>
            <Flex gap={10} vertical>
            <Space.Compact style={couponStyle}>
              <Input placeholder="Enter coupon code" value={code} onChange={e => setCode(e.target.value)} />
              <Button type="primary" onClick={onSubmitCoupon} loading={wait}>Apply</Button>
            </Space.Compact>
            <Text type="secondary">You can apply a coupon code to get a discount on your subscription</Text>
            </Flex>
          </Card>
        </Col>
      </Row>

      <Flex>
        {portalUrl && (
          <Button type="primary" style={{ marginRight: 10 }}>
            <Link to={portalUrl}>Manage Subcription</Link>
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

const labelStyle: React.CSSProperties = {
  color: theme.getDesignToken().colorTextTertiary,
}

const couponStyle: React.CSSProperties = {
  width: '100%',
}

const items = {
  status: 'Status',
  tier: 'Tier',
  availableCompanies: (
    <Flex gap={5} align="center">
      <Text style={labelStyle}>Companies</Text>
      <HelpTooltip text="The number of companies included in your subscription" />
    </Flex>
  ),
  activeCompanies: (
    <Flex gap={5} align="center">
      <Text style={labelStyle}>Active Companies</Text>
      <HelpTooltip text="The number of companies you have connected" />
    </Flex>
  ),
  pricePerCompany: 'Price per company',
  subscriptionPrice: (
    <Flex gap={5} align="center">
      <Text style={labelStyle}>Subscription price</Text>
      <HelpTooltip text="Includes any discounts or promotions" />
    </Flex>
  ),
  nextChargeDate: 'Next charge date',
}

export const AppSumoAlert = () => {
  return <Alert message="Hey Sumo-lings!" description="You are currently in an AppSumo special plan" showIcon />
}

export const TrialAlert = ({ nextChargeDate }: { nextChargeDate: any }) => {
  return (
    <Alert
      message={'Your trial membership ends on ' + formatDate(nextChargeDate)}
      description="At the end of your trial, you will automatically be downgraded to the free tier. To continue to access your data, please upgrade your subscription and enter your credit card information."
      type="warning"
      showIcon
    />
  )
}
