import { Flex, Select, Typography } from 'antd'
import { accountingMethods } from '../Financial'
import { AccountingMethodType } from 'types/chart.types'

type SelectAccountingMethodProps = {
  value: AccountingMethodType
  onChange: (value: AccountingMethodType) => void
  disabled: boolean
}

export function SelectAccountingMethod(props: SelectAccountingMethodProps) {
  const { value, onChange, disabled } = props

  return (
    <Flex align="center">
      <Typography.Text>Accounting Method:</Typography.Text>
      &nbsp;&nbsp;
      <Select
        value={value}
        onChange={onChange}
        options={accountingMethods}
        style={{ width: 180 }}
        disabled={disabled}
      />
    </Flex>
  )
}
