import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Col, DatePicker, Dropdown, Flex, Form, Input, Modal, Row, Select, Upload } from 'antd'
import { EllipsisOutlined, UploadOutlined } from '@ant-design/icons'
import { useForm } from 'antd/lib/form/Form'
import { ProjectStatus } from 'types/project.types'
import { PROJECT_STATUS_OPTIONS } from 'constants/options'
import { useProjectList } from 'hooks/useProjects'
import { useAllMembers } from 'hooks/useAllMembers'
import { useTask } from 'hooks/useTask'
import { ITask } from 'types/task.types'
import { useFileManager } from 'hooks/useFileManager'
import { formatFileFieldValue, getFileNameFromUrlWithQuery } from 'utils/form'
import { TaskComments } from 'pages/workfow/components/TaskComments'
import DocumentEditor from './DocumentEditor'

interface Props {
  open: boolean
  onClose: () => void
  initialValues?: ITask
  onSuccess?: () => void
  projectId?: string
}

export function AddTaskModal(props: Props) {
  const [form] = useForm()
  const { open, onClose, initialValues, onSuccess, projectId } = props
  const isEditMode = !!initialValues
  const defaultValues = useMemo(() => ({ status: ProjectStatus.New }), [])
  // TODO: fix type
  const [defaultFileList, setDefaultFileList] = useState<any[]>([])
  const showProjectField = !projectId && !isEditMode

  const { projectList, isLoading: projectListLoading } = useProjectList({ pagination: { current: 1, pageSize: 1000 } })
  const { data: allMembers, isLoading: allMembersLoading } = useAllMembers()
  const { create, createLoading, update, updateLoading, remove } = useTask()
  const { uploadSingle, uploadSingleLoading, deleteSingle } = useFileManager()

  const projectOptions = useMemo(
    () => projectList?.map((project) => ({ label: project.name, value: project.id })),
    [projectList],
  )

  // TODO: fix type
  const asigneeOptions = useMemo(
    () => allMembers?.map((member: any) => ({ label: `${member.firstName} ${member.lastName}`, value: member.id })),
    [allMembers],
  )

  const menuProps = {
    items: [
      {
        label: 'Delete',
        key: '1',
        danger: true,
        onClick: () => {
          const onRemoveSuccess = () => {
            onModalClose()
            onSuccess?.()
          }
          remove({ id: initialValues?.id }, onRemoveSuccess)
        },
      },
    ],
  }

  // set initial values when edit mode
  useEffect(() => {
    const shouldExit = !open || !isEditMode || !initialValues

    if (shouldExit) {
      return
    }

    const initialAttachments = initialValues.files?.map((item: string, index: number) => ({
      url: item,
      uid: index,
      name: getFileNameFromUrlWithQuery(item),
      status: 'done',
      response: item,
    }))

    form.setFieldsValue({
      title: initialValues.title,
      dueDate: initialValues.dueDate ? dayjs(initialValues.dueDate) : null,
      status: initialValues.status,
      company: initialValues.company?.id,
      description: initialValues.description,
      user: allMembers && !allMembersLoading ? initialValues.user?.id : undefined,
      attachments: { fileList: initialAttachments },
    })

    if (initialAttachments?.length) {
      setDefaultFileList(initialAttachments)
    }
  }, [initialValues, open])

  // TODO: fix type
  const onModalClose = (e?: any) => {
    // remove uploaded files on create mode modal close
    const triggeredByUser = !!e

    if (!isEditMode && triggeredByUser) {
      const uploads = form.getFieldValue('attachments')
      const fileList = uploads?.fileList || []
      // TODO: fix type
      fileList.forEach((file: any) => onCustomDelete(file))
    }

    setDefaultFileList([])
    form.resetFields()
    onClose()
  }

  // TODO: fix type
  const onProjectAdd = async (values: any) => {
    const { attachments, project: projectField, ...rest } = values
    const files = formatFileFieldValue(attachments)
    const project = projectField || projectId

    const onActionSuccess = () => {
      onModalClose()
      onSuccess?.()
    }

    if (isEditMode) {
      const updatePayload = { ...rest, id: initialValues?.id, project, files }
      update(updatePayload, onActionSuccess)
    } else {
      const createPayload = { ...rest, project, files }
      create(createPayload, onActionSuccess)
    }
  }

  // TODO: fix type
  const onCustomUpload = async (props: any) => {
    const res = await uploadSingle(props.file)
    props.onSuccess(res.url, props.file)
  }
  // TODO: fix type
  const onCustomDelete = (e: any) => {
    const file = e.response
    deleteSingle({ file })
    return true
  }

  return (
    <Modal
      width={1080}
      title={
        <Flex justify="space-between">
          {isEditMode ? 'Edit Task' : 'Add Task'}
          {isEditMode && (
            <Flex style={{ marginRight: '24px', marginBottom: '14px' }}>
              <Dropdown menu={menuProps} trigger={['click']} placement="bottomRight">
                <EllipsisOutlined style={{ fontSize: '16px', transform: 'rotate(90deg)' }} />
              </Dropdown>
            </Flex>
          )}
        </Flex>
      }
      open={open}
      onCancel={onModalClose}
      cancelText="Cancel"
      okText={isEditMode ? 'Update' : 'Create'}
      okButtonProps={{ form: 'task-form', htmlType: 'submit', disabled: uploadSingleLoading }}
      confirmLoading={createLoading || updateLoading}
    >
      <Form form={form} onFinish={onProjectAdd} layout="vertical" id="task-form" initialValues={defaultValues}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Enter Task Name"
              name="title"
              rules={[{ required: true, message: 'Please enter the task name!' }]}
            >
              <Input placeholder="Enter Task Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {showProjectField ? (
            <Col span={12}>
              <Form.Item label="Project" name="project" rules={[{ required: true, message: 'Please select project!' }]}>
                <Select
                  loading={projectListLoading}
                  disabled={projectListLoading}
                  placeholder="Select an option"
                  options={projectOptions}
                  showSearch={true}
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
          ) : null}

          <Col span={12}>
            <Form.Item label="Assignee" name="user">
              <Select
                placeholder="Assignee"
                loading={allMembersLoading}
                disabled={allMembersLoading}
                options={asigneeOptions}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Due Date" name="dueDate" normalize={(value) => dayjs(value)}>
              <DatePicker placeholder="Optional" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" name="status">
              <Select placeholder="Select an option" options={PROJECT_STATUS_OPTIONS} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <DocumentEditor />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="" name="attachments">
              <Upload
                key={defaultFileList.length}
                customRequest={onCustomUpload}
                onRemove={onCustomDelete}
                listType="picture"
                multiple
                defaultFileList={defaultFileList}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{isEditMode ? <TaskComments entityId={initialValues.id} entityType="task" /> : null}</Col>
        </Row>
      </Form>
    </Modal>
  )
}
