
export enum BillingAction { None, Trial, Subscribe, Upgrade, Downgrage, UpdateCard }

export interface ISubscription {
  id: string
  created: number
  status: string
  paymentMethod: string
  planPrice: number
  unitPrice: number
  fullPrice: number
  discountPrice: number
  nextCharge: {
    date: number,
    amount: number
  } | null,
  discounts: {
    end: number
    coupon: {
      amount_off: number,
      percent_off: number,
      duration: string
      metadata?: {
        quantity?: string
      }
    }
  }[],
  quantity: number
  history: ISubscriptionHistory[]
  invoice: any
  meta: any

  isActive?: boolean
  nextChargeDate?: number
  accessLevel?: AccessLevel
  isAppSumo?: boolean
  paymentMethods?: any[]
}

export interface ISubscriptionHistory {
  billing_reason: string
  created: number
  amount_paid: number
}

export interface IPromoCode {
  id: string
  coupon: any
}

export interface IPlan {
  id: string
  trialDays: number
  list: IPrice[]
}

export interface IPrice {
  id: string
  name: string
  price: number
  accessLevel: AccessLevel
}

export interface IPaymentMethod {

}

export enum AccessLevel { Free, Pro, Advanced }