import { useState } from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { ModalTxList } from 'components/ModalTxList'
import { ColorNumber } from 'components/ColorNumber'

import { useTxModal } from 'hooks/useTxModal'
import { useComparativeReport } from 'hooks/useAccouningReport'
import { formatDate, parseDate, periodEnd } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import type { ITableRow } from 'types/report.type'

interface Props {
  params: any
  expand: number
}

export function ReportTable(props: Props) {
  const { params, expand } = props

  const report = useComparativeReport(params)
  const txModal = useTxModal()

  const [openModal, setOpenModal] = useState<string[]>([])
  const expandedRowKeys = updateExpandedRowKeys(report.data, expand)

  const columns = (params.type !== 'cashflow') ? baseColumns : baseColumns.slice(0, 3)

  if (params.date_macro === 'month') {
    columns[1].title = formatDate(params.from, 'MMM YYYY')
    columns[2].title = formatDate(params.to, 'MMM YYYY')
  } else if (params.date_macro === 'quarter') {
    const q1 = parseDate(params.from)?.quarter()
    const q2 = parseDate(params.to)?.quarter()
    columns[1].title = 'Q' + q1 + ' ' + formatDate(params.from, 'YYYY')
    columns[2].title = 'Q' + q2 + ' ' + formatDate(params.to, 'YYYY')
  } else {
    columns[1].title = formatDate(params.from, 'YYYY')
    columns[2].title = formatDate(params.to, 'YYYY')
  }

  columns[0].render = (value: string, record) => record.type === 'Summary' ? <b>{value}</b> : value
  columns[1].render = (_, row) => renderValue(1, _, row, 'baseValue')
  columns[2].render = (_, row) => renderValue(2, _, row, 'comparativeValue')
  if (params.type !== 'cashflow') {
    columns[3].render = (_, row) => renderValue(3, _, row, 'varianceAmount')
    columns[4].render = (_, row) => renderValue(4, _, row, 'variancePercentage', 'percent')
  }

  const onDetails = (record: ITableRow, i: number) => {
    const accounts = record.id ? [record.id] : getTreeIds([record])
    const { from, to } = i === 1 
      ? { from: params.from, to: periodEnd(params.from, params.date_macro as any) }
      : { from: params.to, to: periodEnd(params.to, params.date_macro as any) }
    txModal.open({ accounts, from, to, method: params.method })
  }

  const renderValue = (i: number, v: number, record: ITableRow, key: string, type?: 'percent' ) => {
    const sum: any = record.rows?.find(v => v.type === 'Summary')
    const value = v || sum?.[key]

    if (record.type === 'Section') {
      if (expandedRowKeys.includes(record.key))
        return ''
    } 

    const isVariance = (key === 'varianceAmount') || (key === 'variancePercentage')
    const isBold = record.type === 'Summary'
    
    if (isVariance)
      return <ColorNumber amount={value} type={type} bold={isBold} />
    
    if (record.type === 'Summary') 
      return <b>{toCurrency(value)}</b>

    return (
      <span className='DottedLink' onClick={() => onDetails(record, i)}>
        {toCurrency(value)}
      </span>
    )
  }

  return (
    <>
      <Table
        rowKey={'key'}
        dataSource={report.data}
        columns={columns}
        pagination={false}
        loading={report.isLoading}
        rowClassName={(record: ITableRow) => record.type || ''}
        expandable={{
          childrenColumnName: 'rows',
          expandIcon: () => null,
          expandedRowKeys,
        }}
        key={params.accountType}
      />
      <ModalTxList 
        open={!!openModal.length} 
        accounts={openModal} 
        onCancel={() => setOpenModal([])}
        from={params.from}
        to={periodEnd(params.from, params.date_macro as any)}
        method={params.method}
      />
    </>
  )
}

const baseColumns: ColumnsType<ITableRow> = [
  {
    title: '',
    dataIndex: 'name',
    ellipsis: true,
  },
  {
    title: 'Amount1',
    dataIndex: 'baseValue',
    width: 200,
  },
  {
    title: 'Amount2',
    dataIndex: 'comparativeValue',
    width: 200,
  },
  {
    title: 'Variance $',
    dataIndex: 'varianceAmount',
    width: 200,
  },
  {
    title: 'Variance %',
    dataIndex: 'variancePercentage',
    width: 200,
  },
]

function getTreeIds(data: any[]) {
  const r: string[] = []

  function process(data: any[]) {
    data.forEach(item => {
      r.push(item.id)
      if (item.rows?.length > 0) process(item.rows)
    })
  }

  process(data)
  return r.filter(v => v)
}

function updateExpandedRowKeys(data: any[], level: number) {
  const currentKeys: string[] = []

  function process(data: any[], level: number) {
    if (!level) return currentKeys
    data.forEach(item => {
      if (item.rows?.length > 0) {
        currentKeys.push(item.key)
        process(item.rows, level - 1)
      }
    })
  }

  process(data, level)
  return currentKeys
}
