import { Button, Divider, Flex, List, Modal, Tag, Typography, notification } from 'antd'
import { useRutterLink } from 'react-rutter-link'

import { CardIntegration } from 'components/CardIntegration'
import { MatchAccounts } from 'components/MatchAccounts'

import { api } from 'utils/axios'
import { config } from 'utils/config'
import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { useAccounts } from 'hooks/useAccounts'
import { useCompanyStore } from 'store/company.store'
import { ConnectionType } from 'types/company.types'
import { IConnectionItem, ConnectionAction } from 'types/connection.types'
import { RoleType } from 'types/user.types'

const { Text } = Typography

export function Integrations() {
  const companyId = useCompanyStore((state) => state.id)

  const { company, update, revalidate } = useCompany()
  const { accounts, isLoading } = useAccounts()
  const { isAllowed } = useRole(RoleType.Member)

  const { open } = useRutterLink({
    publicKey: config.rutter.publicKey,
    onSuccess: (token: string) => { onSuccess(token) }
  })

  const connections = (company?.connections || [])
    .filter((i) => i.type.id === ConnectionType.Rutter)
    .map(i => ({ ...i, connectionInfo: JSON.parse(i.connectionInfo.toString()) }))

  const isShopify = !!connections.length
  const isSync = connections.some((i) => i.status === 2)
  const isConnected = !isSync && connections.some((i) => i.status === 1)

  items[0].connected = isConnected
  items[0].status = isShopify ? 
    isSync ? 'syncing' : 'connected'
    : 'not connected'

  const onSuccess = async (token: string) => {
    const postData = { token, provider: 'Rutter' }
    await api.post(`companies/${company?.id || companyId}/connection/`, postData)
    notification.success({ message: 'Connected to Shopify' })
    revalidate()
  }

  const onDisconnect = async () => {
    if (!isAllowed) return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
    await api.delete(`companies/${company?.id}/connection/${connections[0].keyId}`)
    notification.success({ message: 'Disconnected from Shopify' })
    revalidate()
  }

  const onClick = (item: IConnectionItem, action: ConnectionAction) => {
    if (action === ConnectionAction.Connect) {
      if (!isAllowed) return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
      if (item.title === 'Shopify') open({ platform: 'Shopify' })
    }
    else if (action === ConnectionAction.Setup) {
      const modal = Modal.info({
        title: item.title + ' integration',
        content: (
          <Flex vertical gap={10} style={{ marginTop: 10, marginBottom: 20 }}>
            <Text strong>Connected stores:</Text>
            {connections.map((i) => (
              <Flex key={i.keyId} justify="space-between">
                <Text>{i.connectionInfo?.store?.store_name || 'Store'}</Text>
                <Tag color="blue">{i.status === 1 ? 'active' : 'syncing'}</Tag>
              </Flex>
            ))}
            <Flex>
              <Button danger onClick={() => { onDisconnect(); modal.destroy() }}>Disconnect</Button>
            </Flex>
          </Flex>
        ),
      })
    }
  }

  const onMatchedChange = (value: Matched) => {
    const settings = { ...company?.settings, merchant: { matched: value } }
    update({ ...company, settings })
  }

  const matched = company?.settings?.merchant?.matched || {}

  if (!company) return null

  return (
    <Flex vertical>
      <Divider orientation="left">Integration</Divider>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={items}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <CardIntegration item={item} onClick={onClick} />
          </List.Item>
        )}
      />
      {isShopify && (
        <Flex vertical gap={20}>
          <Divider orientation="left">Settings</Divider> 
          <Text>We need to know how you match your e-commerce data to your general ledger accounts</Text>
          <MatchAccounts
            value={matched}
            onChange={onMatchedChange}
            accounts={accounts}
            loading={isLoading}
            options={shopifyAccounts}
            placeholder='Select Account(s)'
            multiple
            disabled={!isAllowed}
          />
        </Flex>
      )}
    </Flex>
  )
}

type Matched = { [key: string]: string[] }

const items: IConnectionItem[] = [
  {
    image: require('assets/shopify.png'),
    title: 'Shopify',
    disabled: true,
    connected: true,
  },
  {
    image: require('assets/amazon.png'),
    title: 'Amazon',
    disabled: true,
  },
  {
    image: require('assets/walmart.png'),
    title: 'Walmart',
    disabled: true,
  },
  {
    image: require('assets/etsy.png'),
    title: 'Etsy',
    disabled: true,
  },
  {
    image: require('assets/squarespace.png'),
    title: 'Squarespace',
    disabled: true,
  },
  {
    image: require('assets/ebay.png'),
    title: 'Ebay',
    disabled: true,
  }
]

const shopifyAccounts = [
  {id: 'gross', name: 'Gross Sales', isCalc: false},
  {id: 'discount', name: 'Discounts', isCalc: false},
  {id: 'returns', name: 'Returns', isCalc: false},
  {id: 'net', name: 'Net Sales', isCalc: true},
  {id: 'shipping', name: 'Shipping', isCalc: false},
  // {id: 'total', name: 'Total', isCalc: true},
  // {id: 'payments', name: 'Payments', isCalc: true},
]