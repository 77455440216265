import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export function FullLoader() {
  return <Spin style={spinStyle} indicator={<LoadingOutlined style={iconStyle} spin />} />
}

const spinStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}

const iconStyle: React.CSSProperties = {
  fontSize: 34,
}