import useSWRMutation from 'swr/mutation'
import { ICompany } from 'types/company.types'
import { IProject } from 'types/project.types'
import { api } from 'utils/axios'

export function useProject() {
  const { trigger: createProject, isMutating: createLoading } = useSWRMutation(`projects`, createFetcher)
  const { trigger: createProjectFromTemplate, isMutating: creteFromTemplateLoading } = useSWRMutation(
    `projects`,
    createProjectFromTemplateFetcher,
  )
  const { trigger: createProjectTemplate, isMutating: createTemplateLoading } = useSWRMutation(
    `projects/template`,
    createTemplateFetcher,
  )
  const { trigger: updateProject, isMutating: updateLoading } = useSWRMutation(`projects`, updateFetcher)
  const { trigger: removeProject, isMutating: removeLoading } = useSWRMutation(`projects`, deleteFetcher)
  const { trigger: copyProject, isMutating: copyLoading } = useSWRMutation(`projects/copy`, copyFetcher)
  const { data: project, trigger: getProject, isMutating: getLoading } = useSWRMutation(`projects`, getFetcher)

  const create = (data: Partial<IProject>, onSuccess?: () => void) => {
    createProject(data, { onSuccess })
  }

  const get = (data: Partial<IProject>, onSuccess?: () => void) => {
    getProject(data, { onSuccess })
  }

  const update = (data: Partial<IProject>, onSuccess?: () => void) => {
    updateProject(data, { onSuccess })
  }

  const remove = (data: Partial<IProject>, onSuccess?: () => void) => {
    removeProject(data, { onSuccess })
  }

  const createTemplate = (data: { project: IProject['id']; name: IProject['name'] }, onSuccess?: () => void) => {
    createProjectTemplate(data, { onSuccess })
  }

  // TODO: fix data type
  const copy = (data: any, onSuccess?: () => void) => {
    copyProject(data, { onSuccess })
  }

  const createFromTemplate = (data: { company: ICompany['id']; id: IProject['id'] }, onSuccess?: () => void) => {
    createProjectFromTemplate(data, { onSuccess })
  }

  return {
    create,
    createLoading,
    createTemplate,
    createTemplateLoading,
    update,
    updateLoading,
    remove,
    removeLoading,
    copy,
    copyLoading,
    get,
    getLoading,
    project,
    createFromTemplate,
    creteFromTemplateLoading,
  }
}

interface IUpdateData {
  arg: Partial<ICompany> | Partial<IProject>
}

const createFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post(url, arg).then((res) => res.data)
}

const createTemplateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post(url, arg).then((res) => res.data)
}

const createProjectFromTemplateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post(url + '/' + arg.id, arg).then((res) => res.data)
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.patch(url + '/' + arg.id, arg).then((res) => res.data)
}

const getFetcher = (url: string, { arg }: IUpdateData) => {
  return api.get(url + '/' + arg.id).then((res) => res.data)
}

const deleteFetcher = (url: string, { arg }: IUpdateData) => {
  return api.delete(url + '/' + arg.id).then((res) => res.data)
}

const copyFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post(url, arg).then((res) => res.data)
}
