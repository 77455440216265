import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import { config } from 'utils/config'
import { setCompanyStore } from './company.store'

interface AuthState {
  uid: string | null
  token: string | null
  loading: boolean
  setAuth: (uid: string) => void
  setToken: (token: string | null) => void
  setLoading: (loading: boolean) => void
  reset: () => void
}

const initialState = {
  loading: false, 
  uid: null,
  token: null,
}

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      ...initialState,
      setAuth: uid => set(() => ({ uid })),
      setToken: token => set(() => ({ token })),
      setLoading: loading => set(() => ({ loading })),
      reset: () => { set(initialState) },
    }),
    {
      name: 'auth-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  )
)

const setAuthLoading = (loading: boolean) => useAuthStore.getState().setLoading(loading)

const setAuth = (uid: string | null) => {
  uid 
    ? useAuthStore.getState().setAuth(uid) 
    : useAuthStore.getState().reset()

  setCompanyStore(uid || '')
}
const setToken = (token: string | null) => {
  useAuthStore.getState().setToken(token || null) 
}

const getAuth = () => {
  return useAuthStore.getState().uid
}

if (config.isLocal && config.isDev) {
  mountStoreDevtool('Auth', useAuthStore)
}

export { useAuthStore, getAuth, setAuth, setToken, setAuthLoading }
