import { DatePicker, Flex, Select, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { PickerProps } from 'antd/es/date-picker/generatePicker'
import { PeriodType } from 'types/chart.types'
import { serializedColumnByTypes } from '../Financial'

type DateType = Dayjs | null

type SelectPeriodProps = {
  periodValue: PeriodType
  onPeriodChange: (value: PeriodType) => void
  fromDate: DateType
  toDate: DateType
  onFromDateChange: (d: DateType) => void
  onToDateChange: (d: DateType) => void
}

export function SelectPeriod(props: SelectPeriodProps) {
  const { periodValue, onPeriodChange, fromDate, toDate, onFromDateChange, onToDateChange } = props
  const picker = periodValue?.toLowerCase() as PickerProps['picker']

  const disabledComparedDate = (current: Dayjs) => {
    if (!toDate) return false

    const disabledDatesByPeriod: Record<'Month' | 'Year' | 'Quarter', Dayjs> = {
      Month: toDate.clone().add(-1, 'months'),
      Quarter: toDate.clone().add(-1, 'quarter'),
      Year: toDate.clone().add(-1, 'year'),
    }

    const periodStart = periodValue ? disabledDatesByPeriod[periodValue] : null
    return current.isAfter(periodStart, 'day')
  }

  return (
    <Flex align="center">
      <Typography.Text>From the</Typography.Text>
      &nbsp;&nbsp;
      <Select
        options={serializedColumnByTypes.map((i) => ({ ...i, value: i.label }))}
        style={{ width: 100 }}
        value={periodValue}
        onChange={onPeriodChange}
      />
      &nbsp;&nbsp;
      <Typography.Text>of</Typography.Text>
      &nbsp;&nbsp;
      <DatePicker
        picker={picker}
        value={fromDate}
        onChange={onFromDateChange}
        disabledDate={disabledComparedDate as any}
        maxDate={dayjs()}
      />
      &nbsp;&nbsp;
      <Typography.Text>to</Typography.Text>
      &nbsp;&nbsp;
      <DatePicker picker={picker} value={toDate} onChange={onToDateChange} maxDate={dayjs()} />
    </Flex>
  )
}
