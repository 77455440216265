import { Flex } from 'antd'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { config } from 'utils/config'

export function AuthPage(props: PropsWithChildren) {
  return (
    <Flex style={pageStyle} vertical>
      <GoogleOAuthProvider clientId={config.googleClient.id}>{props.children}</GoogleOAuthProvider>
    </Flex>
  )
}

const pageStyle: React.CSSProperties = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(180deg, #F8F9FA 0%, #E9ECEF 100%)',
}
