import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import { config } from 'utils/config'

interface CompanyState {
  id: number | null,
  setCompany: (id: number | null) => void,
  reset: () => void,
}

const initialState = {
  id: null,
}

const useCompanyStore = create<CompanyState>()(
  persist(
    (set) => ({
      ...initialState,
      setCompany: (id: number | null) => set(() => ({ id })),
      reset: () => { set(initialState) },
    }),
    {
      name: 'company-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)

const setCompanyStore = (userUid: string) => {
  // useCompanyStore.persist.setOptions({
  //   name: `company-storage-${userUid}`,
  // })
}


if (config.isLocal && config.isDev) {
  mountStoreDevtool('Auth', useCompanyStore)
}

export { useCompanyStore, setCompanyStore }