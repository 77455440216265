import useSWR from 'swr'

import { api } from 'utils/axios'
import { IParamsGetProjects, PaginatedResponse } from '../types/project.types'
import { ITask } from 'types/task.types'

export function useTasks({
  sorting,
  pagination,
  project,
  title,
  selectedCompanyIds,
  selectedMemberIds,
  selectedStatusIds,
}: any) {
  const params: any = {
    ...(sorting.field &&
      sorting.order && {
        orderBy: sorting.field,
        orderDir: sorting.order === 'ascend' ? 'ASC' : 'DESC',
      }),
    ...(selectedCompanyIds?.length && {
      companies: selectedCompanyIds.join(','),
    }),
    ...(selectedMemberIds?.length && {
      user: selectedMemberIds.join(','),
    }),
    ...(selectedStatusIds?.length && {
      status: selectedStatusIds.join(','),
    }),
    limit: pagination.pageSize,
    offset: (pagination.current - 1) * pagination.pageSize,
    project,
    title: title || undefined,
  }

  const key = ['/tasks', params]

  const { data, error, mutate, isValidating } = useSWR<PaginatedResponse<ITask>>(key, () => fetch(params))
  const refetch = () => mutate(data, ...key)

  return {
    tasksList: data?.data,
    totalCount: data?.count,
    isLoading: !error && !data,
    isError: error,
    mutate,
    refetch,
    isValidating,
  }
}

const fetch = async (params: IParamsGetProjects) => {
  const res = await api.get('/tasks', { params })
  return res.data
}
