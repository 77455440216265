import { browserDetect } from './browser'
import { getIdToken } from './firebase'

interface IAuthCredentials {
  token: string
}

const PLUGIN_REFRESH_INTERVAL = 4000; // Check every 4 seconds to refresh plugin status

console.info('isPluginInstalled', isPluginInstalled())
console.info('isPluginNeedInstall', isPluginNeedInstall())

export function isPluginNeedInstall() {
  const browser = browserDetect()
  const allowed = ['chrome', 'edge', 'opera', 'brave']
  if (allowed.includes(browser)) {
    return !isPluginInstalled()
  }
  return false
}

export function isPluginInstalled() {
  return !!document.documentElement.hasAttribute('equility-extension')
}

function keepPluginAlive() {
  if (isPluginNeedInstall()) {
    console.warn("Plugin needs installation!")
    return
  }

  // Set or update the last validation time for the plugin
  localStorage.setItem('plugin-last-validation', Date.now().toString())

  // Repeat the check to ensure plugin remains active
  setTimeout(keepPluginAlive, PLUGIN_REFRESH_INTERVAL)
}

// Start the plugin keep-alive mechanism
keepPluginAlive()

window.addEventListener(
  'message',
  function (event: any) {
    // We only accept messages from this window to itself [i.e. not from any iframes]
    if (event.source !== window) return

    if (event.data.type && event.data.type === 'GET_AUTH') {
      getIdToken().then((token) => {
        shareAuth(token ? { token } : null)
      })
    }
  },
  false
)

export function shareAuth(auth: IAuthCredentials | null) {
  const data = { type: 'FROM_PAGE_SETAUTH', auth }
  window.postMessage(data, '*')
}

export function runReviewSync(params: IRunReview) {
  const { from, to, companyId } = params

  const daterange = from + '--' + to
  const accountingMethod = params.method
  const company = { qbo_id: params.realmId, id: companyId }
  const backurl = getReviewBackUrl(companyId, daterange)

  localStorage.setItem('qbosync', JSON.stringify({ date: Date.now(), url: backurl, accountingMethod }))
  localStorage.setItem('compliance-' + companyId + '-' + daterange, accountingMethod)

  const data = { type: "FROM_PAGE_TASK", company, backurl }
  window.postMessage(data, "*")
}

function getReviewBackUrl(companyId: number, daterange: string) {
  const { host, protocol } = window.location
  const pathname = `/company/${companyId}/review/run?daterange=${daterange}`
  return `${protocol}//${host}${pathname}`
}

interface IRunReview {
  from: string
  to: string
  companyId: number
  realmId: string
  method: string
}
