import { useEffect, useMemo, useState } from 'react'
import { Editable, ReactEditor, Slate, withReact, useSelected } from 'slate-react'
import { createEditor, Descendant, Range, Transforms } from 'slate'
import { Button, Flex, Form, TreeSelect, theme } from 'antd'
import { isKeyHotkey } from 'is-hotkey'
import type { BaseEditor } from 'slate'

import { IReportAccount } from 'hooks/useReportAccounts'

const initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

const actions = ['Backspace', 'Delete']
const operators = ['+', '-', '*', '/', '%']
const parentheses = ['(', ')']
const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.']

interface Props {
  value?: Descendant
  defaultvalue?: Descendant
  accounts: any
  onChange: (value: any) => void
  "aria-invalid"?: boolean
}

export const Formula = (props: Props) => {
  const { defaultvalue, accounts, onChange } = props
  const invalid = !!props['aria-invalid']

  const [account, setAccount] = useState<string>('')

  const editor = useMemo(() => withInlines(withReact(createEditor())), [])

  useEffect(() => {
    if (!!defaultvalue && !!editor) {
      editor.children = [defaultvalue]
      editor.onChange()
    }
  }, [defaultvalue, editor])

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (actions.includes(event.key)) return true
    if (parentheses.includes(event.key)) return true
    if (numbers.includes(event.key)) return true
    if (operators.includes(event.key)) return true

    if (event.key === 'a' && event.ctrlKey) return true
    if (event.key === 'a' && event.metaKey) return true

    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
      }
    }

    event.preventDefault()
  }
  
  const getAccountByKey = (key: string | undefined) => {
    if (!key) return
    const flat = treeToFlat(accounts)
    return flat.find((account) => account.key === key)
  }

  const className = invalid 
    ? 'ant-input ant-input-outlined ant-input-status-error' 
    : 'ant-input ant-input-outlined'
  
  return (
    <Flex vertical>
      <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
        <Flex vertical gap={10}>
          <div className='ant-form-item-control-input-content'>
          <Editable
            aria-describedby="formula_help"
            renderElement={(props) => <Element {...props} />}
            renderLeaf={(props) => <Text {...props} />}
            className={className}
            style={editorStyle}
            onKeyDown={onKeyDown}
          />
          </div>
          <Flex gap={10} style={{}}>
          <Form.Item style={{ marginBottom: 10 }}>
            <TreeSelect
              disabled={!accounts.length}
              treeData={accounts}
              fieldNames={{ label: 'label', value: 'key', children: 'rows' }}
              placeholder="Please select"
              style={{ width: 400 }}
              onChange={setAccount}
              showSearch
              allowClear
              filterTreeNode={(input, option: any) =>
                (option?.label?.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
            />
            </Form.Item>
            <Button 
              disabled={!account} 
              onClick={() => insertAccount(editor, getAccountByKey(account))}
              children={'Insert account'}
            />
          </Flex>
        </Flex>
      </Slate>
    </Flex>
  )
}

function treeToFlat(tree: IReportAccount[]): IReportAccount[] {
  return tree.map(node => {
    if (node.rows) return [node, ...treeToFlat(node.rows)]
    else return node
  }).flat()
}

function insertAccount(editor: BaseEditor & ReactEditor, account: IReportAccount | undefined) {
  if (!account) return
  Transforms.insertNodes(editor, [{ type: 'badge', children: [{ text: account?.label, meta: { account } }] }])
  ReactEditor.focus(editor, { retries: 3 })
  Transforms.move(editor, { distance: 1, unit: 'offset', edge: 'focus' })
}

const withInlines = (editor: BaseEditor & ReactEditor) => {
  const { isInline, isElementReadOnly, isSelectable } = editor

  editor.isInline = (element) => ['badge'].includes(element.type) || isInline(element)
  editor.isElementReadOnly = (element) => ['badge'].includes(element.type) || isElementReadOnly(element)
  editor.isSelectable = (element) => !['badge'].includes(element.type) && isSelectable(element)

  return editor
}

const BadgeComponent = ({ attributes, children }: any) => {
  const selected = useSelected()
  const props = { ...attributes, contentEditable: false }
  return (
    <span {...props} style={badgeStyle} data-playwright-selected={selected}>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  )
}

const Element = (props: any) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'badge':
      return <BadgeComponent {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const editorStyle: React.CSSProperties = {
  fontSize: 20,
  fontFamily: 'serif',
}

const badgeStyle: React.CSSProperties = {
  margin: '0 4px',
  padding: '2px 10px',
  fontSize: 18,
  backgroundColor: theme.getDesignToken().controlItemBgHover,
  borderRadius: theme.getDesignToken().borderRadius,
}

// The following is a workaround for a Chromium bug where,
// if you have an inline at the end of a block,
// clicking the end of a block puts the cursor inside the inline
// instead of inside the final {text: ''} node
// https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364

const Text = (props: any) => {
  const { attributes, children } = props
  return <span {...attributes}>{children}</span>
}

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)
