import { Flex, Typography } from "antd"
import { IAccount } from "types/account.types"
import { SelectAccount } from "./SelectAccount"

const { Text } = Typography

type Account = { id: string, name: string }
type Matched = { [key: string]: string[] }

interface Props {
  value: Matched
  accounts: IAccount[]
  options: Account[]
  multiple?: boolean
  loading?: boolean 
  placeholder?: string
  oneline?: boolean
  hidelabel?: boolean
  disabled?: boolean
  onChange: (value: Matched) => void
}

export function MatchAccounts(props: Props) {
  const { value, onChange } = props
  const { accounts, options, loading, disabled } = props
  const { hidelabel, placeholder, multiple, oneline } = props

  return (
    <Flex vertical gap={10}>
      {options.map((option) => (
        <Flex key={option.id} align="center" >
          {!hidelabel && (
            <Text style={labelStyle}>{option.name} </Text>
          )}
          <SelectAccount
            defaultValue={value[option.id] || []}
            onChange={(v) => onChange({ ...value, [option.id]: v })}
            accounts={accounts}
            multiple={multiple}
            loading={loading}
            placeholder={placeholder}
            oneline={oneline}
            disabled={disabled}
          />
        </Flex>
      ))}
    </Flex>
  )
}




const labelStyle: React.CSSProperties = {
  width: 250,
  display: 'block',
}
