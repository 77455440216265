import { ITx } from 'types/tx.types'

// Generating all combinations of an array = O(2^n)
function powerset(array: any[]) {
	const results = [[]]
	for (const value of array) {
		const copy = [...results]
		for (const prefix of copy) {
			results.push(prefix.concat(value))
		}
	}
	return results
}

export function combinations(tx: ITx, txs: ITx[], maxOptimization = false): ITx[] {
	if (!tx.amount)
		return []

	const optimized1 = tx.amount > 0
		? txs.filter(v => v.amount < tx.amount)
		: txs.filter(v => v.amount > tx.amount)

	const optimized2 = maxOptimization && (optimized1.length > 10)
		? optimized1.filter(v => v.amount > tx.amount / 20)
		: optimized1

	if (optimized2.length > 10)
		return []

	const maxSum = optimized2.reduce((sum, val: ITx) => sum + val.amount, 0)
	if (tx.amount > maxSum) 
		return []

	return powerset(optimized2)
		.find(v => tx.amount === v.reduce((sum, val: ITx) => sum + val.amount, 0)) || []
}
