import { Button, Flex, Layout, Typography } from 'antd'
import { Link } from 'react-router-dom'

import { ColorAvatar } from 'components/ColorAvatar'

import { useUser } from 'hooks/useUser'

const { Text } = Typography

export function AppHeader() {
  const { user } = useUser()

  if (!user) return null

  const fullName = `${user.firstName} ${user.lastName}`

  return (
    <Layout.Header style={headerStyle}>
      <Flex gap={10} justify="flex-end" align="center" style={headerStyle}>
        <Button type="text">
          <Flex gap={5} align="center" style={accountStyle}>
            <Link to="/account">
              <Flex gap={5}>
                <Text>{fullName || 'User'}</Text>
              </Flex>
            </Link>
            <ColorAvatar size={30} name={fullName} src={user.photoUrl} />
          </Flex>
        </Button>
      </Flex>
    </Layout.Header>
  )
}

const accountStyle: React.CSSProperties = {
  marginTop: -4,
}

const headerStyle: React.CSSProperties = {
  padding: 0,
  paddingTop: 5,
  position: 'absolute',
  right: 10,
  zIndex: 1,
}
