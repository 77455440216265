import { Alert, Button } from 'antd'

import { config } from 'utils/config'
import { isPluginNeedInstall } from 'utils/plugin'

export function AlertPlugin() {
  if (!isPluginNeedInstall()) return null
  return (
    <Alert
      message="Plug-in required for better experience"
      description="Please install the Equility Plug-in to use this feature"
      type="warning"
      showIcon
      action={
        <Button size="small" type="link">
          <a href={config.links.downloadPlugin} target="_blank" rel="noreferrer">
            Download Plug-in
          </a>
        </Button>
      }
    />
  )
}
