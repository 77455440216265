import { useMemo } from 'react'
import { Flex, TreeSelect, Typography } from 'antd'
import { IBaseReport } from 'types/report.type'
import { transform } from 'hooks/useReportAccounts'
import { TreeSelectProps } from 'antd/lib'

type SelectAccountsProps = {
  value: string[]
  onChange: (value: string[]) => void
  options: {
    QB: IBaseReport[] | undefined
    SP: IBaseReport[] | undefined
  }
  loading?: boolean
}

export function SelectAccounts(props: SelectAccountsProps) {
  const { value, onChange, options, loading } = props

  const treeData = useMemo(() => {
    const qbOptions = transform(options.QB)
    const spOptions = transform(options.SP)

    const qbOptionsWithTitle: TreeSelectProps['treeData'] = qbOptions.length
      ? [
          {
            label: <b style={{ cursor: 'default', color: 'black', display: 'block' }}>QuickBooks Online</b>,
            selectable: false,
            disabled: true,
          },
          ...qbOptions,
        ]
      : qbOptions

    const spOptionsWithTitle: TreeSelectProps['treeData'] = spOptions.length
      ? [
          {
            label: <b style={{ cursor: 'default', color: 'black', display: 'block' }}>Shopify</b>,
            selectable: false,
            disabled: true,
          },
          ...spOptions,
        ]
      : spOptions

    return [...qbOptionsWithTitle, ...spOptionsWithTitle]
  }, [options])

  return (
    <Flex align="baseline">
      <Typography.Text>Showing: </Typography.Text>
      &nbsp;&nbsp;
      <TreeSelect
        placeholder="Search for a metric"
        value={value}
        onChange={onChange}
        loading={loading}
        multiple
        showSearch
        treeData={treeData}
        maxTagCount="responsive"
        style={{ minWidth: 400, maxWidth: 600 }}
        fieldNames={{ label: 'label', value: 'key', children: 'rows' }}
        filterTreeNode={(input, option: any) => {
          if (!input) return false
          if (!option?.label?.toLocaleLowerCase) return false
          return (option.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase() ?? '')
        }}
      />
    </Flex>
  )
}
