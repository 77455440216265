import { Outlet } from 'react-router-dom'
import { Flex, Layout } from 'antd'

import { AppFooter } from './AppFooter'
import { AppSider } from './AppSider'
import { AppHeader } from './AppHeader'
import { Events } from './Events'
import { useUser } from 'hooks/useUser'
import { FullLoader } from 'components/FullLoader'

export function AppLayout() {
  const { user, isLoading } = useUser()

  if (isLoading || !user) return <FullLoader />
  
  return (
    <Layout style={layoutStyle}>
      <Events />
      <AppSider />
      <Layout>
        <Flex vertical style={contantStyle}>
          <AppHeader />
          <Outlet />
          <AppFooter />
        </Flex>
      </Layout>
    </Layout>
  )
}


const layoutStyle: React.CSSProperties = {
  height: '100vh',
}

const contantStyle: React.CSSProperties = {
  position: 'relative',
  minHeight: '100vh',
  overflowY: 'auto',
  background: 'white',
}
