import { CloseCircleOutlined, MoreOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Dropdown, Table, notification } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ITeamMember } from 'types/user.types'
import { formatDateTime } from 'utils/dates'

type InvitedMembersProps = {
  data: any
  loading: boolean
  resendInvite: ({ id }: { id: number }) => Promise<void>
  rescindInvite: ({ id }: { id: number }) => Promise<void>
}

function InvitedMembers(props: InvitedMembersProps) {
  const { data, loading, rescindInvite, resendInvite } = props

  const onResend = async (user: ITeamMember) => {
    await resendInvite({ id: user.id })
    notification.info({ message: 'Invitation successfully sent' })
  }

  const onRescind = (user: ITeamMember) => {
    rescindInvite({ id: user.id })
  }

  return (
    <Table
      dataSource={data}
      columns={getColumns(onResend, onRescind)}
      pagination={false}
      loading={loading}
      rowKey={'id'}
    />
  )
}

export default InvitedMembers

const ActionsColumn = (props: { onResend: () => void; onRescind: () => void }) => {
  const onResend = () => {
    props.onResend()
  }

  const onRescind = () => {
    props.onRescind()
  }

  const menuItems = [
    { label: 'Resend ', key: '1', icon: <ReloadOutlined />, onClick: onResend },
    { label: 'Rescind', key: '2', icon: <CloseCircleOutlined />, onClick: onRescind },
  ]

  return (
    <Dropdown trigger={['click']} menu={{ items: menuItems, onClick: (e) => e.domEvent.stopPropagation() }}>
      <Button
        shape="circle"
        size="small"
        type="link"
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <MoreOutlined />
      </Button>
    </Dropdown>
  )
}

const getColumns = (
  onResend: (user: ITeamMember) => void,
  onRescind: (user: ITeamMember) => void,
): ColumnsType<any> => [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Invited On',
    dataIndex: 'invitedAt',
    render: (date) => (date ? formatDateTime(date) : '-'),
  },
  {
    title: 'Invited By',
    dataIndex: 'invitedBy',
    render: (member) => member?.firstName + ' ' + member?.lastName,
  },
  {
    title: 'Action',
    key: 'action',
    render: (user: ITeamMember) => {
      return <ActionsColumn onResend={() => onResend(user)} onRescind={() => onRescind(user)} />
    },
  },
]
