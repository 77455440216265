import useSWR from 'swr'
import { api } from 'utils/axios'
import { IAuthCredentials, IBodyAuthUser } from 'types/auth.types'

const fetcher = (url: string, body: IBodyAuthUser) => api.post<IAuthCredentials>(url, body).then((res) => res.data)

export function useAuthUser() {
  const { data, error, mutate } = useSWR(['/auth/user', null], fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })

  const authUser = async (body: IBodyAuthUser) => {
    try {
      return await mutate(() => fetcher('/auth/user', body), false)
    } catch (error: any) {
      const message = error.response?.data.message || error.message
      throw new Error(message || 'Auth failed')
    }
  }

  return {
    userData: data,
    isUserLoading: !error && !data,
    isUserError: error,
    authUser,
  }
}
