import React from 'react'
import { createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import { useLocation, useNavigationType } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { config } from './config'

export const sentry = {
  init: (dsn: string) => {
    Sentry.init({
      dsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      environment: config.env,
      initialScope: {
        tags: {
          start: new Date().toISOString(),
          url: window.location.href,
          host: window.location.hostname,
        },
      },
    })
  },
}
