import { api } from 'utils/axios'
import useSWRInfinite from 'swr/infinite'
import { PaginatedResponse } from 'types/project.types'
import { IComment } from 'types/comment.types'
import { unstable_serialize } from 'swr/infinite'
import { useSWRConfig } from 'swr'
import { uniqBy } from 'lodash'

export function useInfiniteComments({ entityType, entityId, pagination }: any) {
  const { mutate } = useSWRConfig()

  const params: any = {
    entityType,
    entityId,
    limit: pagination.pageSize,
  }

  const getKey = (index: number) => {
    const paginationConfig = { ...params, offset: index * pagination.pageSize }
    const searchParams = new URLSearchParams(paginationConfig)
    return searchParams ? `/comments?${searchParams}` : '/comments'
  }

  const mergeComments = () => {
    const allComments: Array<any> = []
    comments?.forEach((comment) => {
      allComments.push(...comment.data)
    })
    return uniqBy(allComments, 'id')
  }

  const {
    data: comments,
    isLoading: commentsLoading,
    isValidating: commentsValidating,
    size,
    setSize,
  } = useSWRInfinite<PaginatedResponse<IComment>>(getKey, getFetcher)

  const totalCount = comments?.[0]?.count
  const allComments = mergeComments()

  const loadMoreComments = () => {
    const allComments = mergeComments()
    if (totalCount && totalCount <= allComments.length) {
      return
    }

    setSize((prev) => prev + 1)
  }

  const refetch = () => mutate(unstable_serialize(getKey))

  return {
    comments: allComments,
    totalCount,
    commentsLoading,
    commentsValidating,
    refetch,
    loadMoreComments,
    size,
    setSize,
  }
}

const getFetcher = async (params: any) => {
  const res = await api.get(params)
  return res.data
}
