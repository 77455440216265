import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { RoleType } from 'types/user.types'
import { api } from 'utils/axios'

export function useTeam() {
  const { data, error, isLoading, isValidating, mutate } = useSWR('team')
  const refetchTeam = () => mutate(data)

  const { isMutating: deleteLoading, trigger: deleteMember } = useSWRMutation('team/user', deleteFetcher, {
    onSuccess: refetchTeam,
  })

  const { isMutating: updateRoleLoading, trigger: updateRole } = useSWRMutation('team/user-role', updateFetcher, {
    onSuccess: refetchTeam,
  })

  const { isMutating: updateCompanyAccessLoading, trigger: updateCompanyAccess } = useSWRMutation(
    'team/user-company',
    updateUserCompanyFetcher,
    { onSuccess: refetchTeam },
  )

  return {
    data,
    error,
    isLoading: isLoading || isValidating,
    refetchTeam,
    deleteLoading,
    deleteMember,
    updateRole,
    updateRoleLoading,
    updateCompanyAccessLoading,
    updateCompanyAccess,
  }
}

export function useTeamInvites() {
  const { data, error, isLoading, isValidating, mutate } = useSWR('team/invites', {})
  const refetch = () => mutate(data)

  return { data, error, refetch, isLoading: isLoading || isValidating }
}

export function useTeamInvite() {
  const { data, isMutating: inviteLoading, trigger: invite } = useSWRMutation('team/invite', inviteFetcher)
  return { data, inviteLoading, invite }
}

export function useTeamResendInvite() {
  const { data, isMutating: resendInviteLoading, trigger: resendInvite } = useSWRMutation('team/invite', resendFetcher)
  return { data, resendInviteLoading, resendInvite }
}

export function useTeamRescindInvite() {
  const {
    data,
    isMutating: rescindInviteLoading,
    trigger: rescindInvite,
  } = useSWRMutation('team/invite', rescindFetcher)

  return { data, rescindInviteLoading, rescindInvite }
}

type InviteFetcherPayload = {
  email: string
  role: RoleType
  companies?: { id: number | undefined; name: string | undefined }[]
}

type DeleteFetcherPayload = {
  id: number
}

type UpdateFetcherPayload = {
  user: number
  role: RoleType
}

type UpdateUserCompanyFetcherPayload = {
  user: number
  company: number
  access: boolean
}

const inviteFetcher = (url: string, { arg }: { arg: InviteFetcherPayload }) => {
  return api.post(url, arg).then((res) => res.data)
}

const deleteFetcher = (url: string, { arg }: { arg: DeleteFetcherPayload }) => {
  return api.delete(`${url}/${arg.id}`).then((res) => res.data)
}

const updateFetcher = (url: string, { arg }: { arg: UpdateFetcherPayload }) => {
  return api.patch(url, arg).then((res) => res.data)
}

const updateUserCompanyFetcher = (url: string, { arg }: { arg: UpdateUserCompanyFetcherPayload }) => {
  return api.patch(url, arg).then((res) => res.data)
}

const resendFetcher = (url: string, { arg }: { arg: { id: number } }) => {
  return api.post(`${url}/${arg.id}/resend`, arg).then((res) => res.data)
}

const rescindFetcher = (url: string, { arg }: { arg: { id: number } }) => {
  return api.delete(`${url}/${arg.id} `).then((res) => res.data)
}
