import { useEffect, useState } from 'react'
import { Input } from 'antd'

interface Props {
  value?: number
  onChange?: (value?: number) => void

  style?: React.CSSProperties
}

export function InputCurrency(props: Props) {
  const { value, onChange: onValueChange } = props
  const { style } = props

  const [current, setCurrent] = useState<string>()

  const _onChange = onValueChange || (() => {})

  useEffect(() => {
    value !== undefined && value !== null
      ? setCurrent(formatDecimal(formatNumber(value)))
      : setCurrent(undefined)
  }, [value])

  const onBlur = (e: any) => {
    if (!current) return _onChange()

    setCurrent(formatDecimal(current))
    _onChange(parseFloat(current.replaceAll(',', '')))
    //e?.relatedTarget?.click()
  }

  const onChange = (v = '') => {
    if (!v) return setCurrent('')

    let nextValue = v
    const isDemical = v.includes('.')

    if (nextValue === '-') return setCurrent('-')

    const vv = nextValue.split('.')
    const decimals = isDemical ? '.' + (vv[1] || '') : ''
    const clean = vv[0].replaceAll(',', '')
    nextValue = formatNumber(clean)

    setCurrent(nextValue + decimals)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') return

    const value = e.currentTarget.value
    const valueSplit = value.split('.')
    const isDemical = value.includes('.') || !value.length

    if (value.length && e.key === '-') e.preventDefault()
    if (!/[0-9]/.test(e.key) && e.key !== '.' && e.key !== '-') e.preventDefault()
    else if (isDemical && e.key === '.') e.preventDefault()
    else if (valueSplit[1]?.length > 1) e.preventDefault()
  }

  return (
    <Input
      style={style}
      placeholder="0.00"
      prefix="$"
      value={current}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      allowClear
    />
  )
}

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

function formatNumber(value: string | number) {
  return numberFormatter.format(parseFloat(value + ''))
}

function formatDecimal(current: string) {
  const isDemical = current.includes('.')
  const valueSplit = current.split('.')

  if (!isDemical) return current + '.00'

  const decimal = (valueSplit[1] + '00').slice(0, 2)
  const nextValue = valueSplit[0] + '.' + decimal

  return nextValue
}