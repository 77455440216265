import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import usePrevious from 'hooks/usePrevious'

type DocumentEditorProps = {
  value?: string
  onChange?: (value: string) => void
}

function createInitialStateFromHtml(value: string) {
  const contentBlock = htmlToDraft(value)
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  return EditorState.createWithContent(contentState)
}

function DocumentEditor(props: DocumentEditorProps) {
  const { onChange, value } = props
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const prevValue = usePrevious(value)

  const onEditorStateChange = (editorState: EditorState) => {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onChange?.(value)
    setEditorState(editorState)
  }

  // set initial content
  useEffect(() => {
    const editorState = createInitialStateFromHtml(value || '')
    const plainText = editorState.getCurrentContent().getPlainText()
    const shouldSetInitialState = prevValue === undefined && plainText

    if (shouldSetInitialState) {
      setEditorState(editorState)
    }
  }, [prevValue, value])

  return (
    <Editor
      editorState={editorState}
      editorStyle={editorStyle}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'list', 'textAlign', 'remove', 'history'],
      }}
    />
  )
}

export default DocumentEditor

const editorStyle: React.CSSProperties = {
  minHeight: '250px',
  padding: '0 20px',
  background: '#ffffff',
  border: '1px solid #d9d9d9',
  borderRadius: '6px',
  wordSpacing: '-1px',
}
