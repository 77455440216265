export function getErrorMsg(err: any) {
  const msg = err.response?.data.message.message || err.message || err.toString()
  if (msg instanceof Array) {
    return { message: 'Error', description: msg[0] }
  }

  if (msg.includes('Firebase')) {
    return { message: 'Error', description: parseFirebaseError(msg) }
  }

  return { message: 'Error', description: msg }
}

function parseFirebaseError(msg: string) {
  const type = msg.replace('Firebase: Error (', '').replace(').', '').trim();

  switch (type) {
    case 'auth/user-not-found':
      return 'Invalid email or password';
    case 'auth/email-already-exists':
      return 'Email not found or already exists';
    case 'auth/wrong-password':
      return 'Invalid email or password';
    case 'auth/email-already-in-use':
      return 'Email already in use. Please sign in';
    case 'auth/invalid-credential':
      return 'The provided credentials are invalid. Please try again.';
    default:
      return msg;
  }
}