import { Tabs, TabsProps } from 'antd'

import { Page } from 'components/Page'
import { Advisory } from './Advisory'

export function HelpPage() {
  return <Page title="Help">
    <Tabs defaultActiveKey="1" items={items} />
  </Page>
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Advisory',
    children: <Advisory />,
  },
  {
    key: '2',
    label: 'FAQs',
    children: 'Under construction',
  },
]
