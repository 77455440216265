import { Flex, Select } from 'antd'

import { accountName } from 'utils/account'
import type { IAccount } from 'types/account.types'

interface Props {
  accounts: IAccount[]
  value?: any
  defaultValue?: any
  width?: number | string
  placeholder?: string
  multiple?: boolean
  oneline?: boolean
  allowClear?: boolean
  loading?: boolean
  disabled?: boolean
  onChange: (value: any) => void
}

export function SelectAccount(props: Props) {
  const { accounts, value } = props
  const { defaultValue, placeholder, onChange } = props
  const { allowClear, multiple, loading, disabled } = props
  const { width, oneline } = props

  const list = (accounts ?? []).map((v) => ({
    name: accountName(v.name, v.number),
    value: v.platformId,
    label: (
      <Flex justify="space-between" align="center" gap={10}>
        <span>{accountName(v.name, v.number)}</span>
        <small>
          <i style={labelStyle}>{v.type}</i>
        </small>
      </Flex>
    ),
  }))

  const mode = multiple ? 'multiple' : undefined

  const selectStyle: React.CSSProperties = {
    width: width || 460,
  }

  return (
    <Flex>
      <Select
        placeholder={placeholder ?? 'Select Account'}
        style={selectStyle}
        value={value || defaultValue}
        defaultValue={defaultValue}
        options={list}
        loading={loading || !accounts?.length}
        onChange={onChange}
        disabled={!accounts?.length || disabled}
        showSearch
        mode={mode}
        maxTagCount={oneline ? 1 : undefined}
        allowClear={allowClear}
        filterOption={(input, option) => (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
      />
    </Flex>
  )
}

const labelStyle: React.CSSProperties = {
  paddingRight: 10,
  opacity: 0.5,
}
