import { CSSProperties, useMemo } from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { PROJECT_STATUS_OPTIONS } from 'constants/options'
import { useAllMembers } from 'hooks/useAllMembers'
import { useCompanies } from 'hooks/useCompanies'

interface ProjectFilter<T> {
  value: T
  onChange: (value: T) => void
}

interface Props {
  isProjectPage: boolean
  onAddTask: () => void
  company: ProjectFilter<number[]>
  member: ProjectFilter<number[]>
  status: ProjectFilter<number[]>
  title: ProjectFilter<string>
}

export function TaskFilters(props: Props) {
  const { company, member, status, title, isProjectPage, onAddTask } = props

  const { data: companies } = useCompanies()
  const { data: allMembers } = useAllMembers()

  const companyOptions = useMemo(
    () => companies?.map((company) => ({ label: company.name, value: company.id })),
    [companies],
  )
  const memberOptions = useMemo(
    () => allMembers?.map((member: any) => ({ label: `${member.firstName} ${member.lastName}`, value: member.id })),
    [allMembers],
  )

  return (
    <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={formStyle}>
      {isProjectPage ? null : (
        <Row gutter={16} style={rowStyle}>
          <Col span={8}>
            <Form.Item label="Search by Task name" style={formItemStyle}>
              <Input
                suffix={<SearchOutlined />}
                allowClear={true}
                placeholder="Search"
                value={title.value}
                onChange={(e) => title.onChange(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Filter by Company" style={formItemStyle}>
              <Select
                placeholder="Select company"
                mode="multiple"
                maxTagCount="responsive"
                showSearch={true}
                optionFilterProp="label"
                options={companyOptions}
                value={company.value}
                onChange={company.onChange}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" onClick={onAddTask}>
              Add Task
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={16} style={rowStyle}>
        <Col span={8}>
          <Form.Item label="Filter by Assignee" style={formItemStyle}>
            <Select
              placeholder="Select member"
              mode="multiple"
              maxTagCount="responsive"
              showSearch={true}
              optionFilterProp="label"
              options={memberOptions}
              value={member.value}
              onChange={member.onChange}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Filter by Status" style={formItemStyle}>
            <Select
              placeholder="Select status"
              mode="multiple"
              maxTagCount="responsive"
              showSearch={true}
              optionFilterProp="label"
              options={PROJECT_STATUS_OPTIONS}
              value={status.value}
              onChange={status.onChange}
            />
          </Form.Item>
        </Col>
        {isProjectPage ? (
          <Col>
            <Button type="primary" onClick={onAddTask}>
              Add Task
            </Button>
          </Col>
        ) : null}
      </Row>
    </Form>
  )
}

const formStyle: CSSProperties = {
  maxWidth: '100%',
}

const formItemStyle: CSSProperties = {
  margin: 0,
}

const rowStyle: CSSProperties = { display: 'flex', alignItems: 'end' }
