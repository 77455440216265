import { Button, Col, Divider, Flex, Form, Input, Popconfirm, Row, notification } from 'antd'
import { useNavigate } from 'react-router-dom'

import { SelectEntity } from 'components/SelectEntity'
import { SelectMethod } from 'components/SelectMethod'

import { useCompany } from 'hooks/useCompany'
import { useRole } from 'hooks/useRole'

import { ICompany } from 'types/company.types'
import { RoleType } from 'types/user.types'

import { useCompanyStore } from 'store/company.store'

export function Info() {
  const { company, update, remove } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)
  const setCompany = useCompanyStore((state) => state.setCompany)
  const navigate = useNavigate()

  if (!company) return null

  const onChange = (values: Partial<ICompany>) => {
    if (!isAllowed)
      return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
    update({ ...company, ...values })
  }

  const onDelete = async () => {
    if (!isAllowed)
      return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })

    await remove(company)
    setCompany(null)
    navigate('/')
  }

  const initialName = company.alias || company.name
  const method = company.settings?.accountingMethod

  return (
    <Flex vertical>
      <Divider orientation="left">{company.name}</Divider>
      <Form layout="vertical" onValuesChange={onChange}>
        <Row gutter={24}>
          <Col span={12}>
            <Flex vertical gap={10}>
              <Form.Item label="Business name" name="alias" initialValue={initialName}>
                <Input placeholder="Business name" disabled={!isAllowed} />
              </Form.Item>
            </Flex>
          </Col>
          <Col span={12}>
            <Flex vertical gap={10}>
              <Form.Item label="Legal entity type" name="entity" initialValue={company.entity}>
                <SelectEntity placeholder="Select" disabled={!isAllowed} />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Divider orientation="left">Settings</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Accounting method" name={['settings', 'accountingMethod']} initialValue={method}>
              <SelectMethod placeholder="Select" disabled={!isAllowed} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Default Currency">
              <Input value={'USD'} disabled />
            </Form.Item>
          </Col>
        </Row>
        {isAllowed && (
          <>
            <Divider orientation="left">Administration</Divider>
            <Popconfirm
              title="Are you sure you want to delete this company? This action cannot be undone."
              onConfirm={onDelete}
            >
              <Button danger>Delete company</Button>
            </Popconfirm>
          </>
        )}
      </Form>
    </Flex>
  )
}
