import { Modal, Typography, Row, Col, Switch, Divider, Flex } from 'antd'
import { useCompanies } from 'hooks/useCompanies'
import { useEffect, useState } from 'react'
import { Fragment } from 'react/jsx-runtime'
import { IAcceptedMember, RoleType } from 'types/user.types'
import { getRoleText } from 'utils/team'

interface Props {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
  selectedMember: IAcceptedMember | null
  updateCompanyAccessLoading: boolean
  updateCompanyAccess: ({ user, company, access }: { user: number; company: number; access: boolean }) => Promise<void>
}

function EditMemberCompaniesModal(props: Props) {
  const { open, onClose, selectedMember, updateCompanyAccess, updateCompanyAccessLoading } = props
  const [companiesLoading, setCompaniesLoading] = useState<number[]>([])

  const { data: allCompanies } = useCompanies()
  const userCompanies = selectedMember?.user.companies
  const isAdminMember = selectedMember?.role === RoleType.Admin

  const onSwitchToggle = async (company: number, access: boolean) => {
    if (!selectedMember?.user.id || companiesLoading.includes(company)) {
      return
    }

    setCompaniesLoading([...companiesLoading, company])

    const payload = {
      company,
      access,
      user: selectedMember.user.id,
    }

    await updateCompanyAccess(payload)
  }

  useEffect(() => {
    if (updateCompanyAccessLoading === false) {
      setCompaniesLoading([])
    }
  }, [updateCompanyAccessLoading])

  return (
    <Modal open={open} onCancel={onClose} title="User Details" footer={false}>
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Typography.Text>
            <b>Name:</b> {selectedMember?.user.firstName + ' ' + selectedMember?.user.lastName}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text>
            <b>Email:</b> {selectedMember?.user.email}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text>
            <b>Role:</b> {selectedMember?.role !== undefined && getRoleText(selectedMember?.role)}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text>
            <b>Companies:</b> {userCompanies?.length} of {allCompanies?.length}
          </Typography.Text>
        </Col>

        <Col span={24}>
          {allCompanies?.map((company) => {
            const checked = !!userCompanies?.find((comp) => comp.id === company.id)
            const loading = companiesLoading.includes(company.id)

            return (
              <Fragment key={company.id}>
                <Flex justify="space-between" align="center">
                  <Typography.Text>{company.alias || company.name}</Typography.Text>
                  <Switch
                    disabled={isAdminMember}
                    checked={checked}
                    onClick={() => onSwitchToggle(company.id, !checked)}
                    loading={loading}
                  />
                </Flex>
                <Divider style={{ margin: '8px' }} />
              </Fragment>
            )
          })}
        </Col>
      </Row>
    </Modal>
  )
}

export default EditMemberCompaniesModal
