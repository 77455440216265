import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Divider, Flex, Form, Input, Row, Typography, notification } from 'antd'
import { Helmet } from 'react-helmet'

import { AppFooter } from '../../app/AppFooter'
import { AuthPage } from './components/AuthPage'
import { Logo } from '../../components/Logo'

import { forgotPassword } from 'utils/firebase'
import { getErrorMsg } from 'utils/geterror'
import { rules } from 'utils/form'

const { Title } = Typography

export function ForgotPasswordPage() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const onCancel = () => {
    navigate('/auth/signin')
  }

  const onSubmit = (values: any) => {
    setLoading(true)
    forgotPassword(values.email)
      .then(() => {
        notification.success({ message: 'Success', description: 'Password reset email sent' })
        navigate('/auth/signin')
      })
      .catch((error) => notification.error(getErrorMsg(error)))
      .finally(() => setLoading(false))
  }

  return (
    <AuthPage>
      <Helmet>
        <title>Equility - Forgot your password</title>
      </Helmet>
      <Row style={contentStyle}>
        <Col span={24}>
          <Flex vertical justify="center" align="center">
            <Title level={1}>
              <Logo />
            </Title>
            <Flex vertical gap={10} align='center'>
              <Title level={3} style={titleStyle}>
                Forgot your password?
              </Title>
              <Form name="forgot" style={formStyle} onFinish={onSubmit}>
                <Form.Item name="email" rules={rules.email}>
                  <Input type="email" placeholder="Enter your email" />
                </Form.Item>
              </Form>
              <Flex gap={10}>
                <Button type="primary" htmlType="submit" form="forgot" loading={loading}>
                  Reset Password
                </Button>
                <Button danger onClick={onCancel}>
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
        </Col>
        <Col span={24}>
          <AppFooter />
        </Col>
      </Row>
    </AuthPage>
  )
}

const contentStyle = {
  width: 800,
}

const titleStyle: React.CSSProperties = {
  marginTop: 0,
  marginBottom: 10,
  width: 500,
  textAlign: 'center',
}

const formStyle: React.CSSProperties = {
  width: 500,
}
