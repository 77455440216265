import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { config } from 'utils/config'
import { getIdToken, logout } from './firebase'

interface CustomConfig extends AxiosRequestConfig {
  _retry: boolean
}

const exclude = ['auth/signin', 'auth/signup', 'auth/refresh', 'auth/google-signin', 'auth/reset-password', 'auth/update-password']

// ----------------------------------------------------------------------

const api = axios.create(config.api)
api.defaults.headers.common['Content-Type'] = 'application/json'

api.interceptors.request.use(async (config) => {
  const accessToken = await getIdToken()
  if (accessToken) config.headers!.Authorization = `Bearer ${accessToken}`

  return config
})

const fetcher = (url: string) => api.get(url).then((res) => res.data)

export { api, fetcher }

// ----------------------------------------------------------------------

api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomConfig
    const status = error.response?.status
    const url = originalRequest?.url || (originalRequest as any)?.config?.url

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const accessToken = await getIdToken()
      if (accessToken) {
        originalRequest.headers!.Authorization = `Bearer ${accessToken}`
        return axios(originalRequest)
      }
    }

    if (status === 401 && originalRequest._retry) {
      logout()
    }

    if (exclude.find(v => url.includes(v)))
      return Promise.reject(error)

    if (status === 403)
      return Promise.reject(error)

    return Promise.reject(error)
  }
)
