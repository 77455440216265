import { CSSProperties, useMemo } from 'react'
import { Col, Form, Modal, Radio, Row } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useCompanies } from 'hooks/useCompanies'
import { IProject } from 'types/project.types'
import { useProject } from 'hooks/useProject'
import { useNavigate } from 'react-router-dom'

interface Props {
  open: boolean
  onClose: () => void
  projectId: IProject['id']
}

export function CreateProjectFromTemplateModal(props: Props) {
  const { open, onClose, projectId } = props
  const navigate = useNavigate()
  const [form] = useForm()
  const companyField = useWatch('company', form)

  const { data: companies } = useCompanies()
  const { createFromTemplate, creteFromTemplateLoading } = useProject()

  const companyOptions = useMemo(
    () => companies?.filter((c) => c.id !== projectId).map((c) => ({ label: c.alias || c.name, value: c.id })),
    [companies, projectId],
  )

  const onModalClose = () => {
    form.resetFields()
    onClose()
  }

  const onProjectCreate = (values: any) => {
    const payload = { id: projectId, company: values.company }

    const onSuccess = () => {
      onModalClose()
      navigate('/workflow/projects')
    }
    createFromTemplate(payload, onSuccess)
  }

  return (
    <Modal
      width={500}
      title="Select Clients"
      open={open}
      onCancel={onModalClose}
      cancelText="Cancel"
      okText="Next"
      okButtonProps={{ form: 'create-project-from-template-form', htmlType: 'submit', disabled: !companyField }}
      confirmLoading={creteFromTemplateLoading}
    >
      <Form form={form} onFinish={onProjectCreate} layout="vertical" id="create-project-from-template-form">
        <Row>
          <Col span={24}>
            <Form.Item label="Client Name" name="company">
              <Radio.Group options={companyOptions} style={RadioGroupStyle} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const RadioGroupStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}
