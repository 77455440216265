import { Link } from 'react-router-dom'

import logo from '../assets/img/logo.png'
import logosm from '../assets/img/logo-sm.png'

interface LogoProps {
  small?: boolean
  width?: number
}

export function Logo(props: LogoProps) {
  const { small, width = 160 } = props

  const src = small ? logosm : logo

  return (
    <Link to="/">
      <img src={src} alt="logo" style={{ width }} />
    </Link>
  )
}
