import { Avatar } from 'antd'

interface Props {
  name: string
  src: string | null
  size?: number
  style?: React.CSSProperties
}

export function ColorAvatar(props: Props) {
  const { name, src, size, style: customStyle } = props

  if (!name) return null

  const backgroundColor = `hsl(${hashCode(name) % 360}, 50%, 60%)`
  const style = { ...customStyle, backgroundColor, opacity: src ? 1 : 0.5 }
  
  const avatarName = getNameInitials(name)

  return (
    <Avatar size={size || 52} src={<Img src={src} />} style={style}>
      {avatarName}
    </Avatar>
  )
}

const Img = ({ src }: { src: string | null }) => {
  return src ? <img referrerPolicy="no-referrer" src={src} alt="avatar" /> : null
}

function getNameInitials(name: string) {
  return name
    .replaceAll(' - ' ,' ')
    .split(' ')
    .slice(0, 3)
    .map((v: string) => v.substring(0, 1))
    .join('')
}

function hashCode(str: string) {
  let hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}
