import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Tabs, TabsProps } from 'antd'

import { Page } from 'components/Page'

import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'

export function AccountPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAllowed } = useRole(RoleType.Owner)

  const path = location.pathname.split('/account/')?.[1] || ''
  const activeKey = path.split('/')[0]
  const items = isAllowed ? allItems : allItems?.slice(0, 1)

  return (
    <Page title="Account">
      <Tabs activeKey={activeKey} items={items} onChange={navigate} />
      <Outlet />
    </Page>
  )
}

const allItems: TabsProps['items'] = [
  {
    key: '',
    label: 'Account Details',
  },
  {
    key: 'billing',
    label: 'Subscription',
  },
]
