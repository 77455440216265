import { useCompanyStore } from 'store/company.store'
import useSWR from 'swr'


interface IParams {
  id: string | null
  to: string
  from: string
}

export function useBankTransactions(params: IParams) {
  const companyId = useCompanyStore((state) => state.id)
  const id = params.id
  const key = id 
    ? `/banks/transactions/?companyId=${companyId}&accountId=${id}&from=${params.from}&to=${params.to}`
    : null

  return useSWR(key)
}
