import React, { memo } from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar, AreaChart, Area } from 'recharts'
import { ChartCustomTooltip } from './ChartCustomTooltip'
import { Result, Spin } from 'antd'
import { ChartType } from 'types/chart.types'
import { AccountFormattedData } from '../../Charts'

type ChartRendererProps = {
  chartDataLoading: boolean
  type: ChartType
  accountsChartData: any
  accountsData: AccountFormattedData[]
}

const ChartRenderer: React.FC<ChartRendererProps> = (props) => {
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300']
  const { type, accountsChartData, accountsData, chartDataLoading } = props

  const formatYAxisTick = (value: number) => {
    if (Math.abs(value) >= 1000) {
      return `${Math.sign(value) * Math.round(Math.abs(value) / 1000)}k`
    }
    return value.toString()
  }

  return (
    <>
      <Spin spinning={chartDataLoading} />
      {accountsChartData?.length === 0 && !chartDataLoading && <EmptyList />}
      
      {type === 'line' && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={accountsChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxisTick} />
            <Tooltip content={<ChartCustomTooltip />} />

            {accountsData.map((account, index) => (
              <Line
                key={`line-${index}`}
                dataKey={account.name}
                type="monotone"
                stroke={colors[index % colors.length]}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
      {type === 'bar' && (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={accountsChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxisTick} />
            <Tooltip content={<ChartCustomTooltip />} />
            {accountsData.map((account, index) => (
              <Bar
                key={`bar-${index}`}
                stackId={`bar-${index}`}
                dataKey={account.name}
                fill={colors[index % colors.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
      {type === 'area' && (
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={accountsChartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxisTick} />
            <Tooltip content={<ChartCustomTooltip />} />
            {accountsData?.map((account, index) => (
              <Area
                key={`area-${index}`}
                type="monotone"
                dataKey={account.name}
                fill={colors[index % colors.length]}
                stackId={`area-${index}`}
                stroke="#8884d8"
                strokeWidth={2}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  )
}

export default memo(ChartRenderer)

const EmptyList = () => {
  return <Result icon={false} title="No report data!" />
}
