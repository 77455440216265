import { useEffect, useState } from 'react'
import { Button, Flex, Modal, Segmented, Table } from 'antd'

import { ColorNumber } from 'components/ColorNumber'

import { formatDate, sortByDate } from 'utils/dates'
import type { ITx } from 'types/tx.types'
import type { IMatcher } from 'types/matcher'

interface Props {
  list: ITx[]
  type: 'bank' | 'qbo'
  matcher: IMatcher
  open: boolean
  onClose: () => void
}

export function MatchModal(props: Props) {
  const { open, onClose } = props
  const { list, type, matcher } = props

  const [filter, setFilter] = useState<Filter>('Suggested')
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    setSelected([])
  }, [open, filter])

  if (!open) return null

  const viewOnly = false
  const matchList = type === 'bank' ? matcher.qbo : matcher.bank
  const isMatched = !!list.filter((v) => v.matchedTo.length || v.forceMatch).length
  const okBtnTitle = isMatched ? 'Unmatch' : 'Match'
  const okBtnType = isMatched ? { danger: true } : ({ type: 'primary' } as any)

  const unmatchedList: ITx[] = list
    .flatMap(v => v.potentialMatches || [])
    .filter((v) => {
        if (!v) return false
        const tx = matcher.qbo.find((q) => q._id === v._id)
          || matcher.bank.find((b) => b._id === v._id)
        if (!tx) return false
        if (tx.forceMatch) return false
        return !tx.matchedTo.length
      })

  const match = isMatched
    ? list.flatMap(v => v.matchedTo)
    : filter === 'All Unmatched'
      ? matchList.filter(v => !v.matchedTo.length)
      : unmatchedList

  const selectedTxs = match.filter(v => selected.includes(v._id))
  const listResult = list.reduce((a, v) => a + v.amount, 0)
  const overalAmount = selectedTxs.reduce((sum, v) => v.amount + sum, 0)
  const enabled = isMatched || (roundTo(overalAmount) === roundTo(listResult))

  const handleSubmit = () => {
    if (!!isMatched) {
      list.forEach(v => matcher.unmatch(v))
    } else {
      matcher.match(list, selectedTxs)
    }
    onClose()
  }

  return (
    <Modal
      width={1400}
      title="Match transactions"
      open={open}
      onCancel={onClose}
      footer={viewOnly ? null : [
        <Button onClick={onClose} key="cancel">Cancel</Button>, 
        <Button onClick={handleSubmit} {...okBtnType} disabled={!enabled} key="ok">{okBtnTitle}</Button>
      ]}
      children={
        <Flex vertical gap={10}>
          <Table
            size="small"
            className="Table TxTable"
            rowKey={'_id'}
            dataSource={list}
            columns={columns}
            pagination={false}
            scroll={{ y: 400 }}
          />
          {!isMatched && (
            <Flex>
              <Segmented
                size="small"
                options={['Suggested', 'All Unmatched']}
                value={filter}
                onChange={(v) => setFilter(v as Filter)}
              />
            </Flex>
          )}
          <Table
            virtual
            size="small"
            className="Table"
            rowKey={'_id'}
            dataSource={match}
            columns={columns}
            pagination={false}
            scroll={{ y: 400 }}
            rowSelection={
              !isMatched
                ? {
                    columnWidth: 50,
                    hideSelectAll: false,
                    selectedRowKeys: selected,
                    onChange: (ids) => setSelected(ids as string[]),
                  }
                : undefined
            }
          />
        </Flex>
      }
    />
  )
}

type Filter = 'Matched' | 'All Unmatched' | 'Suggested'

const columns = [
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    width: 140,
    render: (value: string) => formatDate(value, 'MM-DD-YYYY'),
    sorter: (a: ITx, b: ITx) => sortByDate(a.date, b.date),
  },
  {
    title: 'Account',
    key: 'account',
    dataIndex: 'account',
    ellipsis: true,
    width: 200,
    sorter: (a: ITx, b: ITx) => {
      const aName = a?.account || ''
      const bName = b?.account || ''
      return aName.localeCompare(bName)
    },
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    width: 200,
    ellipsis: true,
    sorter: (a: any, b: any) => {
      const aName = a?.name || ''
      const bName = b?.name || ''
      return aName.localeCompare(bName)
    },
  },
  {
    title: 'Memo',
    key: 'memo',
    dataIndex: 'memo',
    width: 342,
    ellipsis: true,
    sorter: (a: ITx, b: ITx) => a?.memo?.localeCompare(b?.memo || '') || 0,
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    width: 140,
    sorter: (a: ITx, b: ITx) => a?.type?.localeCompare(b?.type || '') || 0,
  },
  {
    title: 'Num',
    key: 'num',
    dataIndex: 'num',
    width: 140,
    sorter: (a: ITx, b: ITx) => a?.type?.localeCompare(b?.type || '') || 0,
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'amount',
    width: 140,
    sorter: (a: ITx, b: ITx) => a.amount - b.amount,
    render: (v: number) => <ColorNumber amount={v} />,
  },
]

function roundTo(amount: number) {
  return parseFloat(amount.toFixed(2))
}
