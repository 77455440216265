import useSWRMutation from 'swr/mutation'
import { ICompany } from 'types/company.types'
import { IProject } from 'types/project.types'
import { ITask } from 'types/task.types'
import { api } from 'utils/axios'

export function useTask() {
  const { trigger: createTask, isMutating: createLoading } = useSWRMutation(`tasks`, createFetcher)
  const { trigger: updateTask, isMutating: updateLoading } = useSWRMutation(`tasks`, updateFetcher)
  const { trigger: removeTask, isMutating: removeLoading } = useSWRMutation(`tasks`, deleteFetcher)
  const { data: task, trigger: getTask, isMutating: getLoading } = useSWRMutation(`tasks`, getFetcher)

  const create = (data: Partial<ITask>, onSuccess?: () => void) => {
    createTask(data, { onSuccess })
  }

  const get = (data: Partial<ITask>, onSuccess?: () => void) => {
    getTask(data, { onSuccess })
  }

  const update = (data: Partial<ITask>, onSuccess?: () => void) => {
    updateTask(data, { onSuccess })
  }

  const remove = (data: Partial<ITask>, onSuccess?: () => void) => {
    removeTask(data, { onSuccess })
  }

  return {
    create,
    createLoading,
    update,
    updateLoading,
    remove,
    removeLoading,
    get,
    getLoading,
    project: task,
  }
}

interface IUpdateData {
  arg: Partial<ICompany> | Partial<IProject>
}

const createFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post(url, arg).then((res) => res.data)
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.patch(url + '/' + arg.id, arg).then((res) => res.data)
}

const getFetcher = (url: string, { arg }: IUpdateData) => {
  return api.get(url + '/' + arg.id).then((res) => res.data)
}

const deleteFetcher = (url: string, { arg }: IUpdateData) => {
  return api.delete(url + '/' + arg.id).then((res) => res.data)
}
