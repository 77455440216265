import { CSSProperties, useMemo, useState } from 'react'
import { Button, Col, Form, Row, Select } from 'antd'
import { PROJECT_STATUS_OPTIONS } from 'constants/options'
import { useAllMembers } from 'hooks/useAllMembers'
import { useCompanies } from 'hooks/useCompanies'
import { AddProjectModal } from 'components/AddProjectModal'

interface ProjectFilter<T> {
  value: T[]
  onChange: (value: T[]) => void
}

interface Props {
  company: ProjectFilter<number>
  member: ProjectFilter<number>
  status: ProjectFilter<number>
  refetch: () => void
}

export function ProjectFilters(props: Props) {
  const { company, member, status, refetch } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { data: companies } = useCompanies()
  const { data: allMembers } = useAllMembers()

  const companyOptions = useMemo(
    () => companies?.map((company) => ({ label: company.alias || company.name, value: company.id })),
    [companies],
  )

  const memberOptions = useMemo(
    () => allMembers?.map((member: any) => ({ label: `${member.firstName} ${member.lastName}`, value: member.id })),
    [allMembers],
  )

  const openAddModal = () => {
    setIsModalVisible(true)
  }

  return (
    <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={formStyle}>
      <Row gutter={16} style={rowStyle}>
        <Col span={6}>
          <Form.Item label="Filter by Company" style={formItemStyle}>
            <Select
              placeholder="Select company"
              mode="multiple"
              maxTagCount="responsive"
              showSearch={true}
              optionFilterProp="label"
              options={companyOptions}
              value={company.value}
              onChange={company.onChange}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Filter by Assignee" style={formItemStyle}>
            <Select
              placeholder="Select member"
              mode="multiple"
              maxTagCount="responsive"
              showSearch={true}
              optionFilterProp="label"
              options={memberOptions}
              value={member.value}
              onChange={member.onChange}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Filter by Status" style={formItemStyle}>
            <Select
              placeholder="Select status"
              mode="multiple"
              maxTagCount="responsive"
              showSearch={true}
              optionFilterProp="label"
              options={PROJECT_STATUS_OPTIONS}
              value={status.value}
              onChange={status.onChange}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={openAddModal} style={{ marginBottom: '0px' }}>
            Add Project
          </Button>
        </Col>
      </Row>
      <AddProjectModal open={isModalVisible} onClose={() => setIsModalVisible(false)} onSuccess={refetch} />
    </Form>
  )
}

const formStyle: CSSProperties = {
  maxWidth: '100%',
}

const formItemStyle: CSSProperties = {
  margin: 0,
}

const rowStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-end',
}
