import { Radio } from 'antd'
import { AreaChartOutlined } from '@ant-design/icons'
import { BarChartOutlined } from '@ant-design/icons'
import { LineChartOutlined } from '@ant-design/icons'

import type { ChartType } from 'types/chart.types'

type Props = {
  value: ChartType
  onChange: (value: ChartType) => void
}

export function SelectChartType(props: Props) {
  const { value, onChange } = props

  return (
    <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
      <Radio.Button value="line">
        <LineChartOutlined />
      </Radio.Button>
      <Radio.Button value="bar">
        <BarChartOutlined />
      </Radio.Button>
      <Radio.Button value="area">
        <AreaChartOutlined />
      </Radio.Button>
    </Radio.Group>
  )
}
