import { useState } from 'react'
import { Col, Form, Input, Modal, Row, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { auth, changePassword, validatePassword } from 'utils/firebase'
import { rules } from 'utils/form'
import { getErrorMsg } from 'utils/geterror'
import { useUser } from 'hooks/useUser'

type FormValues = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

interface Props {
  open: boolean
  onClose: () => void
}

export function ChangePasswordModal(props: Props) {
  const [form] = useForm<FormValues>()
  const [loading, setLoading] = useState(false)
  const { logout } = useUser()
  const { open, onClose } = props

  const onModalClose = () => {
    onClose()
  }

  const onSubmit = async (values: FormValues) => {
    const currentUser = auth.currentUser

    if (!currentUser) {
      return
    }

    const oldPassword = values.oldPassword
    const newPassword = values.newPassword
    setLoading(true)

    try {
      await validatePassword(currentUser, oldPassword)
      await changePassword(currentUser, newPassword)

      form.resetFields()
      notification.success({ message: 'Password successfully changed' })
      onModalClose()
      logout()
    } catch (err) {
      notification.error(getErrorMsg(err))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Change Password"
      open={open}
      onCancel={onModalClose}
      cancelText="Cancel"
      okText="Submit"
      okButtonProps={{ form: 'change-password-form', htmlType: 'submit', disabled: loading }}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical" id="change-password-form">
        <Row gutter={[0, 2]}>
          <Col span={24}>
            <Form.Item name="oldPassword" rules={[{ required: true, message: 'Please enter your old password!' }]}>
              <Input.Password placeholder="Old Password" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="newPassword" rules={rules.password}>
              <Input.Password placeholder="New Password" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="confirmNewPassword"
              rules={[
                { required: true, message: 'Please confirm your new password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Passwords do not match'))
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm New Password" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
