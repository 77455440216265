import { Button, Flex, Modal, Table, Typography } from 'antd'

import { ColorNumber } from 'components/ColorNumber'

import { useTransactions } from 'hooks/useTransactions'
import { useCompanyStore } from 'store/company.store'
import type { IReportRow } from 'types/report.type'
import { toCurrency } from 'utils/numbers'

const { Text } = Typography

interface Props {
  open: boolean
  accounts: string[]
  from: string
  to: string
  method?: string
  onCancel: () => void
}

export function ModalTxList(props: Props) {
  const { open, accounts, from, to, method, onCancel } = props

  const companyId = useCompanyStore((state) => state.id) || 0
  const accountId = accounts.join(',')

  const { data, isLoading } = useTransactions({ companyId, accountId, from, to, method })

  const filteredData = filterTree(data?.report || [])

  const total = filteredData.reduce((acc, item) => {
    const amount = item.type === 'Section' 
      ? item.header?.value || item.summary?.value
      : item['subt_nat_amount']
    console.log(amount, acc)
    return acc + parseFloat(amount || '0')
  }, 0)

  const modal = {
    width: 800,
    onOk: onCancel,
    onCancel,
    footer: <Button type="primary" onClick={onCancel}>Ok</Button>
  }

  return (
    <Modal title="Transactions" {...modal} open={open}>
      <Table
        size='small' 
        virtual
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        expandable={{
          childrenColumnName: 'rows',
        }}
        scroll={{ y: 400 }}
        footer={() => (
          <Flex align="center" justify="flex-end">
            <Text strong>Total: {toCurrency(total)}</Text>
          </Flex>
        )}
      />
    </Modal>
  )
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'tx_date',
    width: 200,
  },
  {
    title: 'Description',
    dataIndex: 'memo',
    ellipsis: true,
    render: (text: string, record: any) => {
      if (record.type === 'Section') {
        return <strong>{record.header?.name || ''}</strong>
      } else {
        return text
      }
    }
  },
  {
    title: 'Amount',
    dataIndex: 'subt_nat_amount',
    width: 150,
    align: 'right' as any,
    render: (v: string, record: any) => {
      if (record.type === 'Section') {
        const total = record.header?.value || record.summary?.value || '0'
        return <ColorNumber amount={parseFloat(total)} />
      }

      const amount = v === '.00' ? '0' : v
      return <ColorNumber amount={parseFloat(amount)} />
    }
  },
]

function filterTree(data: IReportRow[]): IReportRow[] {
  if (!data) return data
  const r: IReportRow[] = []

  data.forEach(item => {
    if (item.type === 'Section') {
      const { rows, ...rest } = item
      r.push({ ...rest, rows: filterTree(rows) })
    } else {
      if (item.tx_date !== 'Beginning Balance')
      r.push(item)
    }
  })

  return r
}