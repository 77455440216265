import { IUser } from './user.types'
import { ICompany } from './company.types'

export interface PaginatedResponse<T> {
  data: T[];
  count: number;
}

export interface IProject {
  allTasks: number,
  completedTasks: number,
  application: ICompany
  dueDate: Date
  startDate: Date
  status: number
  type: number
  id: number
  name: string,
  user: IUser
  company: ICompany
  createdAt: Date
  updatedAt: Date
}

export interface IProjectPostResponse {
  status: boolean
}

export interface IParamsGetProjects {
  orderBy: string,
  orderDir: 'ASC' | 'DESC',
  companies: string[],
  user: string[],
  offset: number,
  limit: number,
  type: number,
  status: string[]
}

export interface IBodyCreateProject {
  name: string;
  recurring: number;
  accounting_period: number;
  startDate: string;
  dueDate: string;
  company: number;
  user: number;
  status: number;
}

export enum ProjectType {
  'TEMPLATE' = 1,
  'BASE' = 2

}

export enum ProjectRecurring {
  'Yes' = 1,
  'No' = 2,
}

export enum ProjectRecurringFrequency {
  'Weekly' = 1,
  'Monthly' = 2,
  'Quarterly' = 3,
  'Yearly' = 4,
}

export enum ProjectStatus {
  'New' = 1,
  'In progress' = 2,
  'Completed' = 3,
  'On hold' = 4,
  'Canceled' = 5,
}
