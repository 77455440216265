import useSWR from 'swr'

export function useCompanyMembers(companyId?: number, onSuccess?: () => void) {

    const key = companyId ? `companies/${companyId}/members` : null;
    const { data, isLoading } = useSWR(key, { onSuccess })

    return {
        companyMembers: data,
        isLoading,
    }
}
