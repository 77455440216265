import React from 'react'
import { Flex, Typography } from 'antd'

import { toCurrency } from 'utils/numbers'

interface CustomTooltipProps {
  active?: boolean
  payload?: any[]
}

export const ChartCustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {

  const getName = (data: any, key: string) => {
    const meta = data?.payload?.meta || {}
    const type = meta[key]
    return key + (type ? ` (${type})` : '')
  }

  if (active && payload && payload.length) {
    return (
      <Flex align="start" style={blockStyle}>
        {payload.map((i) => (
          <Typography.Text key={i.name} style={{ color: i.color }}>
            {`${getName(i, i.name)}: ${toCurrency(i.value)}`}
          </Typography.Text>
        ))}
      </Flex>
    )
  }

  return null
}

const blockStyle: React.CSSProperties = {
  backgroundColor: '#fff',
  padding: '10px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  flexDirection: 'column',
}