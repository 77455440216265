import { Col, Form, Input, Modal, Row, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { auth, validatePassword } from 'utils/firebase'
import { rules } from 'utils/form'
import { getErrorMsg } from 'utils/geterror'

import { useChangeEmail } from 'hooks/useAccounts'
import { useUser } from 'hooks/useUser'

interface Props {
  open: boolean
  onClose: () => void
}

type FormValues = {
  newEmail: string
  password: string
}

export function ChangeEmailModal(props: Props) {
  const [form] = useForm<FormValues>()
  const { changeEmail, changeEmailLoading } = useChangeEmail()
  const { logout } = useUser()
  const { open, onClose } = props

  const onModalClose = () => {
    onClose()
  }

  const onSubmit = async (values: FormValues) => {
    const currentUser = auth.currentUser

    if (!currentUser) {
      return
    }

    const newEmail = values.newEmail
    const password = values.password

    try {
      await validatePassword(currentUser, password)
      await changeEmail({ email: newEmail })
      form.resetFields()
      notification.success({ message: 'Verification email sent' })
      onModalClose()
      logout()
    } catch (err) {
      notification.error(getErrorMsg(err))
    }
  }

  return (
    <Modal
      title="Change Email"
      open={open}
      onCancel={onModalClose}
      cancelText="Cancel"
      okText="Submit"
      okButtonProps={{ form: 'change-email-form', htmlType: 'submit', disabled: changeEmailLoading }}
      confirmLoading={changeEmailLoading}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical" id="change-email-form">
        <Row>
          <Col span={24}>
            <Form.Item name="newEmail" rules={rules.email}>
              <Input placeholder="New Email" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="password" rules={rules.password}>
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
