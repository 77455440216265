import { Rule } from 'antd/lib/form'
import {phone} from 'phone'
export const rules: { [key: string]: Rule[] } = {
  fullName: [{ required: true, message: 'Please input your full name' }],
  firstName: [{ required: true, message: 'Please input your first name' }],
  lastName: [{ required: true, message: 'Please input your last name' }],
  email: [
    { required: true, message: 'Please input your email' },
    { type: 'email', message: 'Invalid email' },
  ],
  password: [
    { required: true, message: 'Please input your password' },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/,
      message: 'Must contain 9 characters, one uppercase, one lowercase, one number and one special case character',
    },
  ],
  phoneNumber: [
    { required: false, message: 'Please input your phone number',
      validator: (_, value) => {
        if (!value) return Promise.resolve() // No need to validate if not required and empty
        const { isValid } = phone(value) // Use phone to check if valid
        return isValid
          ? Promise.resolve()
          : Promise.reject('Invalid phone number')
      },
    },
  ],
  appsumo: [{ required: true, message: 'Please input your AppSumo Code' }],
  terms: [
    {
      validator: (_, value) =>
        value
          ? Promise.resolve()
          : Promise.reject('Please indicate that you have read and agree to the Privacy Policy and Terms of Use'),
    },
  ],
  source: [{ required: true, message: 'Please select' }],
  sourcedetails: [{ required: true, message: 'Please fill out this field' }],
}

export const customRules: { [key: string]: Rule[] } = {
  name: [{ required: true, message: 'Please input your first name', max: 40 }],
  description: [{ required: true, message: 'Please input your last name', max: 100 }],
  account: [{ required: true, message: 'Please select an account' }],
  formula: [
    {
      required: true,
      validator(rule, value) {
        const x = parseFormula(value[0])
        if (!x) return Promise.reject('Invalid formula')
        try {
          // eslint-disable-next-line
          const match = x.match(/^[0-9,\.+,\-,\*,\/,\(,\)]+$/g)
          if (!match) return Promise.reject('Invalid formula')
          // eslint-disable-next-line no-eval
          eval(x)
          return Promise.resolve()
        } catch (error) {
          return Promise.reject('Invalid formula')
        }
      },
      message: 'Invalid formula',
      validateTrigger: ['onBlur', 'onKeyDown'],
    },
  ], // onChange by default
}

function parseFormula(value: any) {
  if (!value?.children) return
  const arr = value.children.map((v: any) => {
    if (v.type === 'badge') return '(1+1)'
    return v.text
  })
  // eslint-disable-next-line
  var z = /^(\([+*%\/\-\d\s]+\)|[+*%\/\-\d\s]+)+$/.test(arr.join(''))
  return arr.join('')
}

export function generateOptionsFromEnum(data: Object) {
  return Object.entries(data)
    .map(([label, value]) => ({ label, value }))
    .filter((opt) => typeof opt.value === 'number')
}

export function getFileNameFromUrlWithQuery(url: string) {
  if (!url) {
    return ''
  }
  const urlWithoutQuery = url.split('?')[0]
  const parts = urlWithoutQuery.split('/')
  return parts[parts.length - 1]
}

export function formatFileFieldValue(files: { fileList: [] }) {
  return files?.fileList?.map((file: any) => file.response)
}
