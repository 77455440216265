import { Tabs, TabsProps } from 'antd'

import { Page } from 'components/Page'
import { FinancialTab } from './Financial'
import { ChartsTab } from './Charts'

export function AnalyticsPage() {
  return (
    <Page title="Analytics">
      <Tabs defaultActiveKey="2" items={items} />
    </Page>
  )
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Financial Statements',
    children: <FinancialTab />,
  },
  {
    key: '2',
    label: 'Charts',
    children: <ChartsTab />,
  },
]
