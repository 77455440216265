import { SettingOutlined } from '@ant-design/icons'
import { animated, useSpring } from '@react-spring/web'
import { Badge, Button, Card, Flex, Typography } from 'antd'

import { IConnectionItem, ConnectionAction } from 'types/connection.types'

interface Props {
  item: IConnectionItem
  onClick: (item: IConnectionItem, action: ConnectionAction) => void
}

const { Text } = Typography


export function CardIntegration(props: Props) {
  const { item, onClick } = props

  const [styles, api] = useSpring(() => ({ opacity: 0 }))
  const [styles2, api2] = useSpring(() => ({ opacity: 1 }))

  const fadeIn = () => {
    api2.start({ opacity: 0.07 })
    api.start({ opacity: 1 })
  }
  const fadeOut = () => {
    api.start({ opacity: 0 })
    api2.start({ opacity: 1 })
  }

  if (item.disabled) return (
    <Card
      style={cardStyle}
      cover={
        <Flex justify="center" align="center" style={cardFlexStyle}>
          <img style={imageStyleDisabled} alt={item.title} src={item.image} />
          <Text type="secondary" style={cardStyleDisabledText}>Coming soon</Text>
        </Flex>
      }
    />
  )

  if (item.status === 'syncing') return (
    <Badge.Ribbon text={'syncing'} color={'orange'}>
      <Card
        style={cardStyle}
        cover={
          <Button style={cardBtnStyle} onClick={() => onClick(item, ConnectionAction.Setup)}>
            <Flex justify="center" align="center" style={cardFlexStyle}>
              <img style={imageStyle} alt={item.title} src={item.image} />
            </Flex>
          </Button>
        }
      />
    </Badge.Ribbon>
  )

  if (item.status === 'not connected' || !item.connected) return (
    <Card
      style={cardStyle}
      cover={
        <Button style={cardBtnStyle} onClick={() => onClick(item, ConnectionAction.Connect)}>
          <Flex justify="center" align="center" style={cardFlexStyle}>
            <img style={imageStyle} alt={item.title} src={item.image} />
          </Flex>
        </Button>
      }
    />
  )

  const btnProps = {
    onMouseOver: fadeIn,
    onMouseOut: fadeOut,
    onClick: () => onClick(item, ConnectionAction.Setup),
  }

  return (
    <Badge.Ribbon text="active">
      <Card
        style={cardStyle}
        cover={
          <Button style={cardBtnStyle} {...btnProps}>
            <animated.div style={styles2}>
              <Flex justify="center" align="center" style={cardFlexStyle}>
                <img style={imageStyle} alt={item.title} src={item.image} />
              </Flex>
            </animated.div>
            <animated.div style={styles}>
              <Flex style={maskStyle} justify="center" align="center">
                <SettingOutlined />
              </Flex>
            </animated.div>
          </Button>
        }
      />
    </Badge.Ribbon>
  )
}

const cardStyle: React.CSSProperties = {
  height: 90,
  userSelect: 'none',
}

const cardBtnStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: 90,
  padding: 0,
}

const cardFlexStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: 90,
  zIndex: 0
}

const cardStyleDisabledText: React.CSSProperties = {
  position: 'absolute',
  bottom: 2,
  right: 7,
}

const imageStyle: React.CSSProperties = {
  width: 140,
}

const imageStyleDisabled: React.CSSProperties = {
  ...imageStyle,
  opacity: 0.3,
}

const maskStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  fontSize: 28,
}
