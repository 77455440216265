import useSWR, { useSWRConfig } from 'swr'

import { api } from 'utils/axios'
import { useCompanyStore } from 'store/company.store'
import type { IAccount } from 'types/account.types'
import useSWRMutation from 'swr/mutation'

export const useAccounts = () => {
  const { mutate: revalidate } = useSWRConfig()

  const companyId = useCompanyStore((state) => state.id)
  const key = companyId ? ['accounting/accounts', companyId] : null

  const { data, isLoading } = useSWR<IAccount[]>(key, () => fetch(companyId))

  return { 
    accounts: data || [], 
    isLoading, 
    revalidate: () => revalidate(['accounting/accounts', companyId]),
  }
}

export const useAccountsById = (companyId: number | undefined) => {
  const key = companyId ? ['accounting/accounts', companyId] : null
  return useSWR<IAccount[]>(key, () => fetch(companyId || null))
}

export const useAccount = (id: string) => {
  const { accounts, isLoading } = useAccounts()
  const account = accounts?.find((a) => a.platformId === id)
  return { account, isLoading }
}

export const useChangeEmail = () => {
  const {
    data,
    trigger: changeEmail,
    isMutating: changeEmailLoading,
  } = useSWRMutation('auth/change-email', changeEmailFetcher)
  return { data, changeEmail, changeEmailLoading }
}

const changeEmailFetcher = (url: string, { arg }: { arg: { email: string } }) =>
  api.post(url, arg).then((res) => res.data)

const fetch = (companyId: number | null) => {
  if (!companyId) return Promise.resolve([])
  return api.get('/accounting/accounts', { params: { companyId } }).then((res) => res.data)
}
