import ReactDOM from 'react-dom/client'

import { ConfigProvider, ThemeConfig } from 'antd'

import { App } from 'app/App'

import { config } from 'utils/config'
import { sentry } from 'utils/sentry'
import { analytics } from 'utils/analytics'

import 'styles/index.css'

const theme: ThemeConfig = {
  hashed: false,
  token: {
    colorLink: '#0F9648',
    colorPrimary: '#0F9648',
    colorSuccess: '#11C675',
    colorError: '#D13055',
    colorInfo: '#0B73EA',
    borderRadius: 6,
    controlHeight: 40,
    colorBgBase: '#FCFCFC',
  },
  components: {
    Button: {
      defaultShadow: 'none',
      primaryShadow: 'none',
      boxShadow: 'none',
      fontWeight: 600,
    },
    Layout: {
      bodyBg: '#F9F9F8',
      headerBg: 'transparent',
      footerBg: 'transparent',
      siderBg: '#F6F6F6',
      triggerBg: 'transparent',
      triggerColor: '#0F9648',
      headerHeight: 48,
    },
    Menu: {
      itemSelectedBg: 'transparent',
      subMenuItemBg: 'transparent',
      collapsedIconSize: 14,
      iconSize: 14,
      itemHeight: 36
    },
    Tooltip: {
      controlHeight: 32
    },
    Select: {
      optionSelectedColor: '#0F9648',
      optionSelectedBg: '#ECF4EF',
    },
    Tabs: {
      
    },
    Table: {
      rowSelectedBg: '#ECF4EF',
      rowSelectedHoverBg: '#ECF4EF',
    },
    Steps: {
      descriptionMaxWidth: 260,
    }
  },
}

if (!config.isLocal) {
  sentry.init(config.sentry.dsn)
  analytics.init(config.ga.id)
}



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (window.self !== window.top) 
  root.render(<div />)

root.render(
  <ConfigProvider theme={theme}>
    <App />
  </ConfigProvider>
)
