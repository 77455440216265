import { useState } from 'react'
import { Button, Flex, Layout } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

import { Logo } from 'components/Logo'
import { AppMenu } from './AppMenu'
import { CompanyMenu } from 'app/CompanyMenu'

export function AppSider() {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Layout.Sider width={250} collapsedWidth={80} collapsible collapsed={collapsed} trigger={null}>
      <Flex vertical gap={10} justify="space-between" style={wrapperStyle}>
        <Flex vertical gap={10} style={contantStyle}>
          <Flex style={logoBlockStyle} justify="space-between" align="center">
            {collapsed ? <Logo width={37} small /> : <Logo width={100} key="full_logo" />}
          </Flex>
          <CompanyMenu />
          <AppMenu />
        </Flex>
        <Flex justify="center">
          <Button
            type="text"
            icon={collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
        </Flex>
      </Flex>
    </Layout.Sider>
  )
}

const wrapperStyle: React.CSSProperties = {
  height: '100%',
  padding: '15px 0',
}

const contantStyle: React.CSSProperties = {
  padding: '0 10px',
}

const logoBlockStyle: React.CSSProperties = {
  minWidth: 100,
  paddingLeft: 10,
  overflow: 'hidden',
}
