import { Badge, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

interface Props {
  text: string
}

export function HelpTooltip(props: Props) {
  const { text } = props

  return (
    <Tooltip title={text}>
      <Badge count={<QuestionCircleOutlined style={iconStyle} />} />
    </Tooltip>
  )
}

const iconStyle: React.CSSProperties = {
  opacity: 0.5,
}