import { Fragment, useEffect, useMemo, useState } from 'react'
import { MoreOutlined, UserDeleteOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { Button, Dropdown, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import EditMemberCompaniesModal from 'components/EditMemberCompaniesModal'
import EditMemberRoleModal from 'components/EditMemberRoleModal'

import { useUser } from 'hooks/useUser'
import { formatDateTime } from 'utils/dates'
import { RoleType } from 'types/user.types'
import type { IAcceptedMember, IUser } from 'types/user.types'

type AcceptedMembersProps = {
  data: any
  loading: boolean
  isAdminUser: boolean
  deleteLoading: boolean
  deleteMember: ({ id }: { id: number }) => Promise<void>
  updateRoleLoading: boolean
  updateRole: ({ user, role }: { user: number; role: RoleType }) => Promise<void>
  updateCompanyAccessLoading: boolean
  updateCompanyAccess: ({ user, company, access }: { user: number; company: number; access: boolean }) => Promise<void>
}

function AcceptedMembers(props: AcceptedMembersProps) {
  const {
    data,
    loading,
    isAdminUser,
    deleteLoading,
    deleteMember,
    updateRole,
    updateRoleLoading,
    updateCompanyAccess,
    updateCompanyAccessLoading,
  } = props

  const { user } = useUser()

  const [selectedMember, setSelectedMember] = useState<IAcceptedMember | null>(null)

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [editRoleModalVisible, setEditoRoleModalVisible] = useState(false)
  const [editCompaniesModalVisible, setEditCompaniesModalVisible] = useState(false)

  const onDeleteUserClick = (user: IAcceptedMember) => {
    setSelectedMember(user)
    setDeleteModalVisible(true)
  }

  const onEditRoleClick = (user: IAcceptedMember) => {
    setSelectedMember(user)
    setEditoRoleModalVisible(true)
  }

  const onUserDelete = async () => {
    selectedMember?.user.id && (await deleteMember({ id: selectedMember.user.id }))
    setDeleteModalVisible(false)
  }

  const tableColumns = useMemo(() => {
    const columns = getColumns(onDeleteUserClick, onEditRoleClick, user)
    return isAdminUser ? columns : columns.filter((c) => c.key !== 'action')
  }, [isAdminUser])

  useEffect(() => {
    if (selectedMember) {
      console.log('update selected member')
      setSelectedMember(data.find((m: IAcceptedMember) => m.user.id === selectedMember.user.id))
    }
  }, [data])

  return (
    <Fragment>
      <Table
        dataSource={data}
        columns={tableColumns}
        pagination={false}
        loading={loading}
        rowKey={'id'}
        onRow={(row) => ({
          onClick: () => {
            if (isAdminUser) {
              setSelectedMember(row)
              setEditCompaniesModalVisible(true)
            }
          },
        })}
      />

      <EditMemberCompaniesModal
        onClose={() => {
          setSelectedMember(null)
          setEditCompaniesModalVisible(false)
        }}
        open={editCompaniesModalVisible}
        selectedMember={selectedMember}
        updateCompanyAccess={updateCompanyAccess}
        updateCompanyAccessLoading={updateCompanyAccessLoading}
      />

      <EditMemberRoleModal
        key={selectedMember?.user.id}
        open={editRoleModalVisible}
        onClose={() => setEditoRoleModalVisible(false)}
        updateRole={updateRole}
        updateRoleLoading={updateRoleLoading}
        user={selectedMember}
      />
      <Modal
        open={deleteModalVisible}
        onCancel={() => {
          setSelectedMember(null)
          setDeleteModalVisible(false)
        }}
        onOk={onUserDelete}
        okButtonProps={{ loading: deleteLoading, danger: true }}
      >
        Are you sure ?
      </Modal>
    </Fragment>
  )
}

export default AcceptedMembers

const ActionsColumn = (props: { onDelete: () => void; onEditRole: () => void }) => {
  const onDeleteUser = () => {
    props.onDelete()
  }

  const onEditRole = () => {
    props.onEditRole()
  }

  const menuItems = [
    { label: 'Edit Role ', key: '1', icon: <UserSwitchOutlined />, onClick: onEditRole },
    { label: 'Delete User', key: '2', icon: <UserDeleteOutlined />, onClick: onDeleteUser },
  ]

  return (
    <Dropdown trigger={['click']} menu={{ items: menuItems, onClick: (e) => e.domEvent.stopPropagation() }}>
      <Button
        shape="circle"
        size="small"
        type="link"
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <MoreOutlined />
      </Button>
    </Dropdown>
  )
}

const getColumns = (onDelete: (user: any) => void, onEditRole: (user: any) => void, me: IUser | undefined): ColumnsType<any> => [
  {
    title: 'Name',
    dataIndex: ['user', 'firstName'],
    render: (_, record) => {
      const name = [record.user.firstName, record.user.lastName].filter(Boolean).join(' ')
      if (record.user.id === me?.id) return <b>{name} (You)</b>
      return name
    },
  },
  {
    title: 'Email',
    dataIndex: ['user', 'email'],
  },
  {
    title: 'Role',
    dataIndex: 'role',
    width: 120,
    render: (role) => {
      if (role === 0) return 'Admin'
      if (role === 1000) return 'Member'
      if (role === 2000) return 'Guest'
    },
  },
  {
    title: 'Last access',
    dataIndex: ['user', 'lastAccess'],
    width: 240,
    render: (date) => (date ? formatDateTime(date) : '-'),
  },
  {
    title: 'Action',
    key: 'action',
    width: 200,
    render: (user: IAcceptedMember, record) => {
      if (record.user.id === me?.id) return null
      return <ActionsColumn onDelete={() => onDelete(user)} onEditRole={() => onEditRole(user)} />
    },
  },
]
