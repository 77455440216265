import { Page } from 'components/Page'
import { TasksPage } from './Tasks'

export function ProjectTasksPage() {
  return (
    <Page title="Tasks">
      <TasksPage />
    </Page>
  )
}
