import { Card, Flex, Typography } from 'antd'

import { useAccount } from 'hooks/useAccounts'
import { toCurrency } from 'utils/numbers'
import type { ITx } from 'types/tx.types'
import type { IReconcile } from 'types/reconcile'
import type { IMatcher } from 'types/matcher'
import { ColorNumber } from 'components/ColorNumber'

interface Props {
  data: IReconcile
  matcher: IMatcher
}

const { Text, Title } = Typography

export function Stats(props: Props) {
  const { data, matcher } = props

  const { account } = useAccount(data?.accountId)

  if (!data) return null

  const isLiability = !!account?.isLiability

  // const bank = data?.temp_data.bank || []
  // const qbo = data?.temp_data.qbo || []

  const clearedQbo = matcher.qbo.filter((v) => v.matchedTo?.length || v.forceMatch)
  const selectedBank = matcher.bank.filter((v) => v.selected && !v.matchedTo?.length)
  const diffData = getDiffData([...clearedQbo, ...selectedBank], data)

  const cleared = data.starting_balance - Math.abs(diffData.paymentsAmount) + diffData.depositsAmount
  const delta = data.ending_balance - cleared
  const endingBalance = data.ending_balance || 0

  const cBalance1 = isLiability ? diffData.depositsAmount : diffData.paymentsAmount
  const cBalance2 = isLiability ? diffData.paymentsAmount : diffData.depositsAmount

  return (
    <Card style={{ height: 180 }} size="small">
      <Flex vertical gap={20} style={{ padding: 20 }}>
        <Flex gap={20} justify="space-between">
          <Flex vertical align="center">
            <Text type="secondary">STATEMENT ENDING BALANCE</Text>
            <Title level={4} style={valueStyle}>
              <ColorNumber amount={endingBalance} size={24} />
            </Title>
          </Flex>
          <Flex vertical>
            <Text>&nbsp;</Text>
            <Title level={4} style={valueStyle}>
              -
            </Title>
          </Flex>
          <Flex vertical align="center">
            <Text type="secondary">CLEARED BALANCE</Text>
            <Title level={4} style={valueStyle}>
              <ColorNumber amount={cleared} size={24} />
            </Title>
          </Flex>
          <Flex vertical>
            <Text>&nbsp;</Text>
            <Title level={4} style={valueStyle}>
              =
            </Title>
          </Flex>
          <Flex vertical align="center">
            <Text type="secondary">DIFFERENCE</Text>
            <Title level={4} style={valueStyle}>
              <ColorNumber amount={delta} size={24} />
            </Title>
          </Flex>
        </Flex>
        <Flex gap={20} style={{ marginLeft: '34%' }} align="flex-end">
          <Flex vertical align="center">
            <Text type="secondary">BEGINNING BALANCE</Text>
            <ColorNumber amount={data.starting_balance} />
          </Flex>
          <Text type="secondary">{isLiability ? '+' : '-'}</Text>
          <Flex vertical align="center">
            <Text type="secondary">{isLiability ? 'CHARGES' : 'PAYMENTS'}</Text>
            <Text>{toCurrency(cBalance1, false)}</Text>
          </Flex>
          <Text type="secondary">{isLiability ? '-' : '+'}</Text>
          <Flex vertical align="center">
            <Text type="secondary">{isLiability ? 'PAYMENTS' : 'DEPOSITS'}</Text>
            <Text>{toCurrency(cBalance2, false)}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}

function getDiffData(diffArr: ITx[], result?: IReconcile) {
  return {
    deposits: diffArr.filter((v) => v.amount > 0).length,
    payments: diffArr.filter((v) => v.amount <= 0).length,
    depositsAmount: diffArr.filter((v) => v.amount > 0).reduce((prev, v) => prev + v.amount, 0),
    paymentsAmount: diffArr.filter((v) => v.amount <= 0).reduce((prev, v) => prev + v.amount, 0),
  }
}

const valueStyle: React.CSSProperties = {
  margin: 0,
  fontWeight: 500,
}
