import dayjs, { Dayjs } from 'dayjs'

import weekday from 'dayjs/plugin/weekday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import localeData from 'dayjs/plugin/localeData'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.locale('en')
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)
dayjs.extend(quarterOfYear)
dayjs.extend(advancedFormat)

// const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY', 'MMDDYYYY'];

type DateType = string | number | Date | Dayjs
type PriodType = 'month' | 'quarter' | 'year'

export function sortByDate(a: string, b: string) {
  return dayjs(a || '0').unix() - dayjs(b || '0').unix()
}

export function formatDate(value?: DateType, format?: string) {
  if (!value) return ''

  if (typeof value === 'string') {
    return dayjs(value.split('T')[0]).format(format || 'LL')
  }

  const daysvalue = value as Dayjs
  if (daysvalue.format) {
    return daysvalue.format(format || 'LL')
  }

  return dayjs(value).format(format || 'LL')
}

export function formatDateTime(value: DateType, format?: string) {
  return dayjs(value).format(format || 'LLL')
}

export function parseDate(value: DateType) {
  if (value === 'Invalid Date') return null
  return dayjs(value)
}

export function fromNow(value: DateType) {
  return dayjs(value).fromNow()
}

export function yearStart(format?: string) {
  return dayjs()
    .startOf('year')
    .format(format || 'YYYY-MM-DD')
}

export function monthStart(format?: string) {
  return dayjs()
    .startOf('month')
    .format(format || 'YYYY-MM-DD')
}

export function prevMonthStart(date?: DateType, format?: string) {
  return dayjs(date)
    .subtract(1, 'month')
    .startOf('month')
    .format(format || 'YYYY-MM-DD')
}

export function prevMonthEnd(format?: string) {
  return dayjs()
    .subtract(1, 'month')
    .endOf('month')
    .format(format || 'YYYY-MM-DD')
}

export function prevPeriodStart(date: DateType, period: PriodType, format?: string) {
  return dayjs(date)
    .subtract(1, period)
    .startOf(period)
    .format(format || 'YYYY-MM-DD')
}

export function periodEnd(date: DateType, period: PriodType = 'month', format?: string) {
  return dayjs(date)
    .endOf(period)
    .format(format || 'YYYY-MM-DD')
}

export function periodStart(date: DateType, period: PriodType = 'month', format?: string) {
  return dayjs(date)
    .startOf(period)
    .format(format || 'YYYY-MM-DD')
}

export function parseDateRage(value?: [string, string]) {
  if (!value) {
    return [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
  }
  return value.map((date) => dayjs(date)) as [Dayjs, Dayjs]
}

// TODO: fix types
export const formatDateByPeriod = (date: any, period: PriodType = 'month') => {
  if (!date) return ''
  if (date.length === 10) return date

  switch (period) {
    case 'month':
      return dayjs(date + '-01').format('YYYY-MM-DD')
    case 'quarter':
      const quarters = ['01', '04', '07', '10']
      const [year, q] = date.split('-')
      console.log(date, 'q', q, 'year', year)
      const month = quarters[q.replace('Q', '') - 1]
      return year + '-' + month + '-01'
    case 'year':
      console.log('1111', date)
      return date.split('-')[0] + '-01-01'
    default:
      return ''
  }
}
