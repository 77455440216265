import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Tabs } from 'antd'

import { Page } from 'components/Page'

import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'

export function ReviewPage() {
  const navigate = useNavigate()
  const location = useLocation()

  const { isAllowed } = useRole(RoleType.Member)

  const path = location.pathname.split('/review/')?.[1] || ''
  const activeKey = path.split('/')[0]

  const tabs = items.filter(v => v.key !== 'result' || activeKey === 'result')

  if (!isAllowed) tabs.splice(0, 2)
  if (!tabs.find(v => v.key === activeKey)) navigate(tabs[0].key)

  return (
    <Page title="Financial Review">
      <Tabs items={tabs} onChange={navigate} activeKey={activeKey} />
      <Outlet />
    </Page>
  )
}

const items = [{
  label: 'Run',
  key: '',
}, {
  label: 'Rules',
  key: 'rules',
}, {
  label: 'History',
  key: 'history',
}, {
  label: 'Result',
  key: 'result',
}]