import { Modal } from 'antd'

import { TxList } from 'components/TxList'

interface Props {
  accounts: string[]
  from: string
  to: string
  method?: string
}

export function useTxModal() {
  const open = (props: Props) => {
    const _ = Modal.info({
      title: 'Transactions',
      content: <TxList {...props}  />,
      width: 800,
      icon: null,
      closable: true,
      onOk: () => _.destroy(),
    })
  }

  return { open }
}
