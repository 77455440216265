import { Flex, Typography, theme } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'

import { Logo } from 'components/Logo'

const { Title, Paragraph } = Typography
const { useToken } = theme

export function AuthWelcome() {
  const { token } = useToken()

  const iconStyle = { color: token.colorPrimary, marginRight: 3 }
  const icon = <CheckCircleOutlined style={iconStyle} />

  return (
    <Flex vertical>
      <Title level={1} style={titleStyle}>
        <Logo />
      </Title>
      <Title level={4} style={titleStyle}>
        {icon} Get started quickly
      </Title>
      <Paragraph style={paragraphStyle}>
        Integrate with QuickBooks Online to <br />
        retrieve financial information seamlessly
      </Paragraph>
      <Title level={4}>{icon} Automate your financial review</Title>
      <Paragraph style={paragraphStyle}>
        Avoid manually reconciling your
        <br />
        accounts with our automation features
      </Paragraph>
      <Title level={4}>{icon} Verify financial accuracy</Title>
      <Paragraph style={paragraphStyle}>
        Receive an automated, unbiased, and
        <br />
        independent assessment of accounting
        <br />
        accuracy
      </Paragraph>
    </Flex>
  )
}

const titleStyle: React.CSSProperties = {
  marginTop: 0,
  marginBottom: 10,
}

const paragraphStyle: React.CSSProperties = {
  marginTop: 0,
  marginBottom: 0,
}
