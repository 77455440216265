import { useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { Col, Flex, Row } from 'antd'

import { FullLoader } from 'components/FullLoader'
import { Details } from './components/Details'
import { Stats } from './components/Stats'
import { TxList } from './components/TxList'

import { useMatcher } from 'hooks/useMatcher'
import { useReconcile } from 'hooks/useReconcile'

export function RecResult() {
  const match = useMatch('company/:companyId/reconcile/result/:id')
  const id = match?.params?.id ? parseInt(match.params.id) : null

  const rec = useReconcile({ id })   
  const matcher = useMatcher()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!rec.data) return
    console.info('Matching transactions...')
    const data = rec.data.temp_data
    matcher.matchAll(data.qbo, data.bank, rec.data.dayrange)

  }, [rec.data])

  if (!rec.data || rec.isLoading) return <FullLoader />

  return (
    <Flex vertical>
      <Row gutter={20}>
        <Col span={8}>
          <Details data={rec.data} matcher={matcher} />
        </Col>
        <Col span={16}>
          <Stats data={rec.data} matcher={matcher} />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <TxList matcher={matcher} type="qbo" title="Account transactions" rec={rec.data} loading={loading} onLoading={setLoading}  />
        </Col>
        <Col span={12}>
          <TxList matcher={matcher} type="bank" title="Bank transactions" rec={rec.data} loading={loading} onLoading={setLoading}  />
        </Col>
      </Row>
    </Flex>
  )
}

