import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FileAddOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Button, Divider, Dropdown, Flex, Typography } from 'antd'
import type { MenuProps } from 'antd'
import dayjs from 'dayjs'
import { IProject, ProjectRecurring, ProjectRecurringFrequency, ProjectStatus, ProjectType } from 'types/project.types'
import { formatDate } from 'utils/dates'
import { useProject } from 'hooks/useProject'
import { AddProjectModal } from 'components/AddProjectModal'
import { CopyProjectModal } from 'components/CopyProjectModal'
import { CreateProjectFromTemplateModal } from 'components/CreateProjectFromTemplateModal'

interface Props {
  project: IProject & { recurring: ProjectRecurring; accounting_period: ProjectRecurringFrequency }
  refetchProject: () => void
}

export function TasksMenu(props: Props) {
  const { project, refetchProject } = props

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isCopyProjectModalVisible, setIsCopyProjectModalVisible] = useState(false)
  const [isCreateFromTemplateModalVisible, setIsCreateFromTemplateModalVisible] = useState(false)

  const { update, remove, createTemplate } = useProject()
  const navigate = useNavigate()

  const onActionSuccess = () => navigate('/workflow/projects')

  const onClose = () => {
    update({ status: ProjectStatus.Completed, id: project.id }, onActionSuccess)
  }

  const onHold = () => {
    update({ status: ProjectStatus['On hold'], id: project.id }, onActionSuccess)
  }

  const onDelete = () => {
    remove({ id: project.id }, onActionSuccess)
  }

  const onSaveTemplate = () => {
    const onSaveTemplateSuccess = () => navigate('/workflow/templates')
    createTemplate({ project: project.id, name: project.name }, onSaveTemplateSuccess)
  }

  const onCopy = () => {
    setIsCopyProjectModalVisible(true)
  }

  const onCreateFromTemplate = () => {
    setIsCreateFromTemplateModalVisible(true)
  }

  return (
    <Flex gap={4} justify="space-between" align="center" style={{ minHeight: '40px' }}>
      <Fragment>
        <Info project={project} />
        <Actions
          projectType={project?.type}
          onEdit={() => setIsEditModalVisible(true)}
          onClose={onClose}
          onHold={onHold}
          onDelete={onDelete}
          onSaveTemplate={onSaveTemplate}
          onCopy={onCopy}
          onCreateFromTemplate={onCreateFromTemplate}
        />
      </Fragment>

      <AddProjectModal
        open={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        isEditMode={true}
        initialValues={project}
        onSuccess={refetchProject}
      />

      <CopyProjectModal
        projectId={project?.id}
        open={isCopyProjectModalVisible}
        onClose={() => setIsCopyProjectModalVisible(false)}
      />

      <CreateProjectFromTemplateModal
        projectId={project?.id}
        open={isCreateFromTemplateModalVisible}
        onClose={() => setIsCreateFromTemplateModalVisible(false)}
      />
    </Flex>
  )
}

interface InfoProps {
  project: IProject & { recurring: ProjectRecurring; accounting_period: ProjectRecurringFrequency }
}

function Info(props: InfoProps) {
  const { project } = props

  const formatedStartDate = formatDate(project?.startDate ? dayjs(project?.startDate) : undefined, 'MMM Do, YYYY')
  const formatedDueDate = formatDate(project?.dueDate ? dayjs(project?.dueDate) : undefined, 'MMM Do, YYYY')

  if (!project) {
    return <div />
  }

  return (
    <Flex align="center">
      {project?.startDate && <Typography.Text>Starts on {formatedStartDate}</Typography.Text>}

      {project?.startDate && <Divider type="vertical" />}

      {project?.dueDate && (
        <Fragment>
          <Typography.Text>Due on {formatedDueDate}</Typography.Text>
          <Divider type="vertical" />
        </Fragment>
      )}

      {project?.recurring && (
        <Typography.Text>{project.recurring === ProjectRecurring.Yes ? 'Recurring' : 'Not Recurring'}</Typography.Text>
      )}

      {project?.accounting_period && (
        <Fragment>
          <Divider type="vertical" />
          <Typography.Text>{ProjectRecurringFrequency[project.accounting_period]}</Typography.Text>
        </Fragment>
      )}

      {project?.status && (
        <Fragment>
          <Divider type="vertical" />
          <Typography.Text>{ProjectStatus[project.status]}</Typography.Text>
        </Fragment>
      )}
    </Flex>
  )
}

type ProjectAction = () => void

interface ActionsProps {
  projectType: IProject['type']
  onEdit: ProjectAction
  onHold: ProjectAction
  onClose: ProjectAction
  onDelete: ProjectAction
  onSaveTemplate: ProjectAction
  onCopy: ProjectAction
  onCreateFromTemplate: ProjectAction
}

function Actions(props: ActionsProps) {
  const { projectType, onEdit, onHold, onClose, onDelete, onSaveTemplate, onCopy, onCreateFromTemplate } = props
  const navigate = useNavigate()

  if (!projectType) {
    return <div />
  }

  const templateProjectActions = [
    {
      key: '1',
      label: 'Create project from template',
      icon: <FileAddOutlined />,
      onClick: onCreateFromTemplate,
    },
    {
      key: '2',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: onEdit,
    },
    {
      key: '3',
      label: 'Delete Project',
      icon: <DeleteOutlined />,
      onClick: onDelete,
    },
  ]

  const projectActions = [
    {
      key: '1',
      label: 'Save As Template',
      icon: <SaveOutlined />,
      onClick: onSaveTemplate,
    },
    {
      key: '2',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: onEdit,
    },
    {
      key: '3',
      label: 'Hold',
      icon: <ClockCircleOutlined />,
      onClick: onHold,
    },
    {
      key: '4',
      label: 'Complete',
      icon: <CloseOutlined />,
      onClick: onClose,
    },
    {
      key: '5',
      label: 'Copy for other client',
      icon: <CopyOutlined />,
      onClick: onCopy,
    },
    {
      key: '6',
      label: 'Delete Project',
      icon: <DeleteOutlined />,
      onClick: onDelete,
    },
  ]

  const items: MenuProps['items'] = projectType === ProjectType.BASE ? projectActions : templateProjectActions

  return (
    <Flex gap={16}>
      <Dropdown menu={{ items }} placement="bottomRight" trigger={['hover']}>
        <Button>
          Actions <DownOutlined />
        </Button>
      </Dropdown>
      <Button type="primary" onClick={() => navigate(-1)}>
        Back
      </Button>
    </Flex>
  )
}
