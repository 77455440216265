import { useState } from 'react'
import { Button, Col, Divider, Flex, Row, Table } from 'antd'
import { useAdmin } from 'hooks/useAdmin'
import type { ColumnsType } from 'antd/es/table'

import { api } from 'utils/axios'
import { formatDate, sortByDate } from 'utils/dates'
import { downloadBlob } from 'utils/download'
import type { IUser } from 'types/user.types'
import { toCurrency } from 'utils/numbers'

export function UsersTab() {
  const users = useAdmin()

  const [loading, setLoading] = useState(false)

  const download = async () => {
    setLoading(true)
    return api.get('admin/users/export', {responseType: 'blob' })
      .then(r => downloadBlob(r.data, 'equility-users.xls'))
      .finally(() => setLoading(false))
  }

  return (
    <Flex vertical gap={10}>
      <Row gutter={24}>
        <Col span={20}>
          <Divider orientation="left">{users.data?.length} total</Divider>
        </Col>
        <Col span={4}>
          <Flex vertical justify="center" style={btnFlexStyle}>
            <Button block onClick={download} loading={loading}>Download</Button>
          </Flex>
        </Col>
      </Row>

      <Table dataSource={users.data} columns={columns} rowKey="id" loading={users.isLoading} />
    </Flex>
  )
}

const columns: ColumnsType<IUser> = [
  {
    title: 'Name',
    dataIndex: 'firstName',
    width: 220,
    ellipsis: true,
    render: (name, record) => `${name} ${record.lastName}`,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    ellipsis: true,
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: 'Last access',
    dataIndex: 'lastAccess',
    render: (date) => (date ? formatDate(date) : '-'),
    sorter: (a, b) => sortByDate(a.lastAccess, b.lastAccess),
    width: 140,
  },
  {
    title: 'Team',
    dataIndex: ['member', 'team', 'name'],
    width: 130,
    ellipsis: true,
  },
  {
    title: 'Role',
    dataIndex: ['member', 'role'],
    width: 90,
    render: (role, user) => {
      if (user.isOwner) return 'Owner'
      if (role === 0) return 'Admin'
      if (role === 1000) return 'Member'
      if (role === 2000) return 'Guest'
    },
  },
  {
    title: 'Companies',
    dataIndex: 'companies',
    width: 110,
    render: (companies) => companies
  },
  {
    title: 'Tier',
    dataIndex: 'accessLevel',
    width: 100,
    sorter: (a, b) => a.accessLevel - b.accessLevel,
    render: (level) => {
      if (level === 1) return 'Pro'
      if (level === 2) return 'Advanced'
      return ""
    }
  },
  {
    title: 'Value',
    dataIndex: 'charge',
    width: 100,
    sorter: (a, b) => (a.charge || 0) - (b.charge || 0),
    render: v => toCurrency(v),
  },
  {
    title: 'Created',
    dataIndex: 'createdOn',
    render: (createdOn) => formatDate(createdOn, 'll'),
    width: 140,
  },
]

const btnFlexStyle: React.CSSProperties = {
  height: '100%',
}