import { Link } from 'react-router-dom'
import { Button, Divider, Flex, Typography } from 'antd'
import { Table, type TableColumnsType } from 'antd'

import { useRules } from 'hooks/useRules'
import { useRole } from 'hooks/useRole'

import { RoleType } from 'types/user.types'
import type { IRule } from 'types/company.types'
import { useCompany } from 'hooks/useCompany'

const { Text } = Typography

export function ReviewRules() {
  const { rules, remove, isLoading } = useRules()
  const { isAllowed } = useRole(RoleType.Member)

  const { company } = useCompany()
  const entity = company?.entity

  const onDelete = (id: number) => {
    remove(id)
  }

  columns[3].render = (_, rule: IRule) => (
    <Flex gap={10}>
      <Link to={`${rule.id}`}>
        <Button size="small" type="text">
          Edit
        </Button>
      </Link>
      <Button size="small" type="text" danger onClick={() =>onDelete(rule.id)}>
        Delete
      </Button>
    </Flex>
  )

  if (!isAllowed) return null

  const filteredDefaultRules =  defaultRules
    .map((rule, i) => ({ ...rule, id: 'dr_' + i + 1 }))
    .filter((rule) => {
      if (!rule.entity) return true
      return rule.entity === entity
    })

  return (
    <Flex vertical gap={10}>
      <Flex vertical>
        <Divider orientation="left">Custom Rules</Divider>
        <Table 
          loading={isLoading}
          dataSource={rules || []}
          columns={columns} 
          rowKey="id" 
          pagination={false}
        />
      </Flex>
      <Flex justify="flex-start" style={{ marginTop: 20 }}>
        <Link to="./add">
        <Button type="primary" style={{ width: 200 }}>
          Add Rule
        </Button>
        </Link>
      </Flex>
      <Flex vertical>
        <Divider orientation="left">Default Review Rules</Divider>
        <Flex vertical gap={20}>
        <Text>
          The default rules are applied to all companies by default based on their business entity type. Custom rules
          can be added to make them more specific to your company.
        </Text>
        <Table 
          size="small"
          dataSource={filteredDefaultRules}
          columns={columns2} 
          rowKey="id" 
          pagination={false}
        />
        </Flex>
      </Flex>
    </Flex>
  )
}

const columns: TableColumnsType<IRule> = [
  {
    title: 'Rule',
    dataIndex: 'name',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true,
  },
  {
    title: 'Source Account',
    dataIndex: 'account',
    width: 300,
    ellipsis: true,
    render: (account) => account?.label,
  },
  {
    title: '',
    dataIndex: 'id',
    width: 150,
  },
]


const columns2: TableColumnsType<any> = [
  {
    title: 'Rule',
    dataIndex: 'name',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
  }
]

const defaultRules = [
  {
    name: 'Checking & credit card reconciliation',
    description: <span>A review of all ‘Bank’ and ‘Credit Card’ GL account types to ensure that: <br />◦ All accounts are reconciled through the financial review ending date<br />◦ All uncleared transactions prior to the close date are flagged for review</span>,
  },
  {
    name: 'Opening balance equity',
    description: <span>This GL account was created to show any opening balance on the bank and credit card accounts. Often, bookkeepers and accountants choose to re-allocate transactions within the ‘Opening Balance Equity’ account to a more standardized GL account (ie, Owners' Contribution). This check flags any transactions that are allocated to the ‘Opening Balance Equity’ GL account, for your review and reference.</span>,
  },
  {
    name: 'Undeposited funds',
    description: <span>When accepting customer payments, most QuickBooks accounts have the option to allocated these payments to the ‘Undeposited Funds’ GL account. If there is a balance in this account, it is likely because a customer payment was mapped to ‘Undeposited Funds’ and it was never embedded into a bank deposit. This check reviews the ‘Undeposited Funds’ balance  as of the review period ending date and flags the account if the balance is not $0.00. It is important to review this balance to ensure that checks are not left undeposited for a long period of time.</span>,
  },
  {
    name: 'Uncategorized assets',
    description: <span>The ‘Uncategorized Asset’ GL account is a default GL account created by QuickBooks. Often, bookkeepers and accountants use this as a clearing account that needs to be emptied before the books are closed. This check flags any transactions that are allocated to the ‘Uncategorized Asset’ GL account, so that they can be reviewed and properly allocated.</span>,
  },
  {
    name: 'Uncategorized transactions',
    description: <span>Often, bookkeepers and accountants will utilize certain clearing accounts for transactions that they might need additional details on before allocating them to the proper GL account. This check asks you to choose your clearing accounts, and then it flags any transactions that are allocated to this GL account, so that they can be reviewed and properly allocated.</span>,
  },
  {
    name: 'Equity Review for Sole Proprietor / Pass-through LLC (Form 1040)',
    description: <span>A review of your equity allocations to ensure that you’re not utilizing an equity account that is not compliant with your entity type. These include:<br />◦ Paid In Capital Or Surplus<br />◦ Partner Contributions<br />◦ Partner Distributions<br />◦ Partners Equity<br />◦ Preferred Stock<br />◦ Treasury Stock</span>,
    entity: 1,
  },
  {
    name: 'Equity Review from Small business corporation, one or more owners (Form 1120S)',
    description: <span>A review of your equity allocations to ensure that you’re not utilizing an equity account that is not compliant with your entity type. This includes:<br />◦ Common Stock<br />◦ Paid In Capital Or Surplus<br />◦ Partner Contributions<br />◦ Partner Distributions<br />◦ Partners Equity<br />◦ Preferred Stock<br />◦ Treasury Stock</span>,
    entity: 3,
  },
  {
    name: 'Equity Review for Partnership (Form 1065)',
    description: <span>A review of your equity allocations to ensure that you’re not utilizing an equity account that is not compliant with your entity type. This includes:<br />◦ Common Stock<br />◦ Paid In Capital Or Surplus<br />◦ Preferred Stock<br />◦ Treasury Stock</span>,
    entity: 2,
  },
  {
    name: 'Equity Review for Nonprofit Organization (Form 990)',
    description: <span>A review of your equity allocations to ensure that you’re not utilizing an equity account that is not compliant with your entity type. This includes:<br />◦ Common Stock<br />◦ Paid In Capital Or Surplus<br />◦ Partners Contributions<br />◦ Partner Distributions<br />◦ Partners Equity<br />◦ Preferred Stock<br />◦ Treasury Stock<br />◦ Owners Equity<br />◦ Accumulated Adjustment<br />◦ Estimated Taxes<br />◦ Health Insurance Premium<br />◦ Health Savings Account Contribution<br />◦ Personal Expenses<br />◦ Personal Income</span>,
    entity: 5,
  },
  {
    name: 'Equity Review for Corporation, one or more shareholders (Form 1120)',
    description: <span>A review of your equity allocations to ensure that you’re not utilizing an equity account that is not compliant with your entity type. This includes:<br />◦ Owners Equity<br />◦ Partner Contributions<br />◦ Partner Distributions<br />◦ Partners Equity<br />◦ Accumulated Adjustment<br />◦ Estimated Taxes<br />◦ Health Insurance Premium<br />◦ Health Savings Account Contribution<br />◦ Personal Expenses<br />◦ Personal Income</span>,
    entity: 4,
  },
  {
    name: 'Asset Review for Sole Proprietor / Pass-through LLC (Form 1040)',
    description: <span>A review of your asset allocations to ensure that you’re not utilizing an asset account that is not compliant with your entity type. This includes:<br />◦ Loans To Stockholders</span>,
    entity: 1,
  },
  {
    name: 'Asset Review for Nonprofit Organization (Form 990)',
    description: <span>A review of your asset allocations to ensure that you’re not utilizing an asset account that is not compliant with your entity type. This includes:<br />◦ Loans to Stockholders</span>,
    entity: 5,
  },
  {
    name: 'Asset Review for Partnership (Form 1065)',
    description: <span>A review of your asset allocations to ensure that you’re not utilizing an asset account that is not compliant with your entity type. This includes:<br />◦ Loans to Stockholders</span>,
    entity: 5,
  },
  {
    name: 'Liabilities Review for Sole Proprietor / Pass-through LLC (Form 1040)',
    description: <span>A review of your liability allocations to ensure that you’re not utilizing a liability account that is not compliant with your entity type. This includes:<br />◦ Shareholder Notes Payable</span>,
    entity: 1,
  },
  {
    name: 'Liabilities Review for Nonprofit Organization (Form 990)',
    description: <span>A review of your liability allocations to ensure that you’re not utilizing a liability account that is not compliant with your entity type. This includes:<br />◦ Shareholder Notes Payable</span>,
    entity: 5,
  },
  {
    name: 'Payroll Review for Sole Proprietor / Pass-through LLC (Form 1040)',
    description: <span>These entities should not have payroll per IRS guidelines. Equility checks to see if there are any transactions within GL accounts that have the following detail types:<br />◦ Payroll Expenses<br />◦ Payroll Tax Expenses<br />◦ Payroll Wage Expenses</span>,
    entity: 1,
  },
  {
    name: 'Depreciation & Amortization',
    description: <span>Ensure that the total of the accumulated deprecation and amortization on your balance sheet during this period match the total of the depreciation and amortization expense on your income statement.</span>
  }
]
