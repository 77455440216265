import { useEffect } from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { Flex, Menu, Select, Tooltip, theme } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'
import type { MenuItemType } from 'antd/es/menu/interface'
import * as Icon from '@ant-design/icons'

import { useCompanies } from 'hooks/useCompanies'
import { useCompanyStore } from 'store/company.store'
import { useAccessLevel } from 'hooks/useAccessLevel'

export function CompanyMenu() {
  const { data, isLoading } = useCompanies()
  const { accessLevel } = useAccessLevel()
  
  const navigate = useNavigate()  
  const match = useMatch('/company/:id/*')

  const companyId = useCompanyStore((state) => state.id)
  const setCompany = useCompanyStore((state) => state.setCompany)

  useEffect(() => {
    if (!data) return
    if (!!companyId && !data.length) {
      setCompany(null)
      navigate('/')
    }
  }, [data])

  if (!companyId) return null
  if (!accessLevel) return null

  function getQboId(companyId: number) {
    const company = data?.find((company) => company.id === companyId)
    const qbo = company?.connections?.find((v) => v.type.id === 1)
    if (!qbo) return ''
    const connectionInfo = JSON.parse(qbo?.connectionInfo.toString())
    const qboId = connectionInfo.realm_id
    return qboId
  }

  const menuItems = getMenuItems(companyId, getQboId(companyId))
    .map((item: any) => accessLevel >= item.accesslevel 
      ? item 
      : { 
          ...item, 
          disabled: true, 
          label:  <Tooltip title={getMsgByAccessLevel(item.accesslevel)}>{item.label}</Tooltip> 
        }
    )

  const path = (match?.params['*'])?.split('/')?.[0]
  const selected = match 
    ? menuItems.find(item => item?.key === path)?.key || menuItems[0]?.key
    : ''

  const companies = data?.map(company => ({ label: company.alias || company.name, value: company.id }))

  return (
    <Flex vertical gap={10}>
      <Flex style={companyStyle}>
        <Select 
          variant="borderless"
          value={companyId}
          options={companies}
          loading={isLoading}
          style={{ width: '100%' }}
          onChange={(id) => navigate(`/company/${id}`)}
        />
      </Flex>
      <Menu mode="inline" 
        items={menuItems} 
        selectedKeys={[selected as string]} 
        style={menuStyle} 
      />
    </Flex>
  )
}

type MenuItems = (companyId: number, qboId: string) => ItemType<MenuItemType>[]
const getMenuItems: MenuItems = (companyId, qboId) => [{
  label: <Link to={`/company/${companyId}`}>Analytics</Link>,
  key: 'analytics',
  icon: <Icon.DashboardOutlined />,
  accesslevel: 0,
  title: 'Analytics',
}, {
  label: <Link to={`/company/${companyId}/review`}>Financial Review</Link>,
  key: 'review',
  icon: <Icon.FileProtectOutlined />,
  accesslevel: 2,
  title: 'Financial Review',
}, {
  label: <Link to={`/company/${companyId}/reconcile`}>Reconciliation</Link>,
  key: 'reconcile',
  icon: <Icon.InteractionOutlined />,
  accesslevel: 0,
  title: 'Reconciliation',
}, {
  label: <Link to={`/company/${companyId}/settings`}>Settings</Link>,
  key: 'settings',
  icon: <Icon.SettingOutlined />,
  accesslevel: 0,
  title: 'Settings',
}, {
  label: <a href={linkToQBO(qboId)} target="_blank" rel="noreferrer">QuickBooks Online</a>,
  key: 'qbo',
  accesslevel: 0,
  icon: <Icon.LinkOutlined />,
}]

const companyStyle: React.CSSProperties = {
  background: 'white',
  borderRadius: theme.getDesignToken().borderRadius,
  marginTop: 10,
}

const menuStyle: React.CSSProperties = {
  borderRadius: theme.getDesignToken().borderRadius,
}

function getMsgByAccessLevel(level: number) {
  switch(level) {
    case 2: return 'Upgrade to Advanced Tier'
    case 1: return 'Upgrade to Pro Tier'
    default: return ''
  }
}

const qboUrl = 'https://c1.qbo.intuit.com/qbo1'

export function linkToQBO(qboId: string, page = 'switchCompany') {
  return `${qboUrl}/login?pagereq=${page}&companyId=${qboId}`
}