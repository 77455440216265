import { RoleType } from 'types/user.types'

export const getRoleText = (role: RoleType | undefined) => {
  switch (role) {
    case RoleType.Admin:
      return 'Admin'
    case RoleType.Member:
      return 'Member'
    case RoleType.ViewOnly:
      return 'Guest'
    default:
      return undefined
  }
}
