import useSWR, { useSWRConfig } from 'swr'

import { api } from 'utils/axios'
import type { IPaymentMethod, IPlan } from 'types/subscription.types'
import type { ISubscription } from 'types/subscription.types'

const refreshInterval = 30000

export function useStripe() {
  const { mutate: revalidate } = useSWRConfig()

  const { data: portalUrl, isLoading: portalLoading } = useSWR<string>('/user/subscription/portal', getPortalUrl, { refreshInterval })
  const { data: plan, isLoading: planLoading } = useSWR<IPlan>('/user/subscription/plans')
  const { data: subscription, isLoading: subLoading } = useSWR<ISubscription>('/user/subscription')

  const isLoading = portalLoading || planLoading || subLoading

  return { 
    portalUrl, 
    plan, 
    subscription, 
    isLoading,
    revalidate: () => {
      revalidate('/user/subscription/portal')
      revalidate('/user/subscription/plans')
      revalidate('/user/subscription')
    }
  }
}

export function useTeamSubcription() {
  return useSWR<IOwnerDataResponse>('/user/subscription/owner')

}

function getPortalUrl() {
  const returnUrl = window.location.href
  return api.get('/user/subscription/portal', { params: { returnUrl } })
    .then(response => response.data)
}

type IOwnerDataResponse = {
  subscription: ISubscription,
  paymentMethods: IPaymentMethod[],
  maxQuantity: number
}