import { Button, Divider, Flex, Table, TableColumnsType, notification } from 'antd'
import { Link } from 'react-router-dom'

import { useReconcile, useReconcileList } from 'hooks/useReconcile'
import { formatDate, formatDateTime } from 'utils/dates'
import { useAccounts } from 'hooks/useAccounts'
import { getErrorMsg } from 'utils/geterror'
import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'
import type { IReconcile } from 'types/reconcile'

export function RecHistory() {
  const { accounts } = useAccounts()
  const { data, isLoading } = useReconcileList()
  const { isAllowed } = useRole(RoleType.Admin)
  const reconcile = useReconcile({ id: null })

  const onDelete = (id: number) => {
    if (!isAllowed) return
    reconcile.delete({ id })
      .then(() => notification.warning({ message: 'Deleted', description: 'Review has been deleted' }))
      .catch((err) => notification.error(getErrorMsg(err)))
  }

  columns[0].render = (id: string) => {
    const account = accounts.find((a) => a.platformId === id)
    return account?.name || id
  }
  
  columns[4].render = (id, record) => {
    return (
    <Flex gap={20}>
      <Link to={`../result/${id}`} state={record}>
        <Button size="small" type="text">
          Details
        </Button>
      </Link>
      <Button size="small" type="text" danger onClick={() => onDelete(id)} disabled={!isAllowed}>
        Delete
      </Button>
    </Flex>)
  }

  return (
    <Flex vertical gap={10}>
      <Divider orientation="left">Reconciliation History</Divider>
      <Table dataSource={data} columns={columns} rowKey="id" pagination={false} loading={isLoading} />
    </Flex>
  )
}

const columns: TableColumnsType<IReconcile> = [
  {
    title: 'Account',
    dataIndex: 'accountId',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (value: string) => formatDateTime(value, 'lll'),
  },
  {
    title: 'Start Date',
    dataIndex: 'from',
    render: (value: string) => formatDate(value, 'LL'),
  },
  {
    title: 'End Date',
    dataIndex: 'to',
    render: (value: string) => formatDate(value, 'LL'),
  },
  {
    title: '',
    dataIndex: 'id',
    width: 100,
  },
]
