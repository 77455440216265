import React, { useEffect, useState } from 'react'
import { Button, Card, Divider, Flex, Spin, Typography, notification } from 'antd'
import { PlaidEmbeddedLink } from 'react-plaid-link'
import type { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'

import { api } from 'utils/axios'
import { useRole } from 'hooks/useRole'
import { usePlaidLinkToken } from 'hooks/usePlaid'
import { useCompany } from 'hooks/useCompany'
import { getErrorMsg } from 'utils/geterror'
import { ConnectionType } from 'types/company.types'
import { RoleType } from 'types/user.types'

const { Text } = Typography

export function Banks() {
  const { token: tokenData } = usePlaidLinkToken()

  const { company, revalidate } = useCompany(false)
  const { isAllowed } = useRole(RoleType.Member)

  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState<IPlaidItem[]>([])

  useEffect(() => {
    const plaidItems = (company?.connections || [])
      .filter((i) => i.type.id === ConnectionType.Plaid)
      .map(v => {
        return {
          keyId: v.keyId,
          status: v.status,
          ...JSON.parse(v.connectionInfo.toString())
        }
      })
      
    setMeta(plaidItems as IPlaidItem[])
  }, [company?.connections])

  const onConnect = (token: string) => {
    console.info('onConnect:', token)

    if (!company?.id) return
    if (!isAllowed) return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
    setLoading(true)
    const postData = { token, provider: 'Plaid' }
    api.post(`companies/${company?.id}/connection/`, postData)
      .then(() => {
        notification.success({ message: 'Connected to Plaid' })
        return revalidate()
      })
      .catch(err => notification.error(getErrorMsg(err)))
      .finally(() => setLoading(false))
  }

  const onExit = (error: any, metadata: any) => {
    console.info('Exit:', error, metadata)
    if (error) {
      notification.error({ message: 'Error', description: error.display_message })
    }
  }

  const onDelete = (m: IPlaidItem) => {
    if (!company?.id) return
    if (!isAllowed) return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
    setLoading(true)
    api.delete(`companies/${company?.id}/connection/${m.keyId}`)
      .then(() => {
        notification.warning({ message: 'Deleted' })
        return revalidate()
      })
      .catch(err => notification.error(getErrorMsg(err)))
      .finally(() => setLoading(false))
  }

  const token = tokenData?.linkToken
  if (!token) return null

  const extra = (m: IPlaidItem) => (
    <Flex gap={20} align="center" justify="center">
      <Text type="secondary">Status: {m.status === 1 ? 'active' : 'pending'}</Text>
      <Button danger size="small" onClick={() => onDelete(m)} loading={loading} disabled={!isAllowed}>Delete</Button>
    </Flex>
  )

  return (
    <Flex vertical>
      {isAllowed && (
        <>
          <Divider orientation="left">Connect your banks and cards</Divider>
          <PlaidEmbeddedLink token={token} style={plaidStyle} onSuccess={onConnect} onEvent={console.log} onExit={onExit} key={token} />
          <Text><small>* Please remember that we cannot and will not make any modifications to your data. Our integrations are needed to pull financial data and our access can be revoked at any time.</small>​</Text>
        </>
      )}
      {!!meta.length && <Divider orientation="left">Configuration</Divider>}
      <Spin spinning={loading}>
        <Flex vertical gap={10}>
          {meta.map((m, i) => (
            <Card key={i} title={m.institution?.name || 'Accounts'} extra={extra(m)} size='small'>
              <Flex vertical gap={10}>
                {m.accounts.map((a, j) => (
                  <Text key={j}>{a.name} <small className='dimmed'>(*{a.mask})</small></Text>
                ))}
              </Flex>
            </Card>
          ))}
        </Flex>
      </Spin>
    </Flex>
  )
}

const plaidStyle: React.CSSProperties = {
  height: 350
}

interface IPlaidItem {
  item_id: string
  institution: PlaidLinkOnSuccessMetadata['institution']
  accounts: PlaidLinkOnSuccessMetadata['accounts']
  keyId: string
  status: number
}