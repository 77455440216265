import { Tabs, TabsProps } from 'antd'

import { Page } from 'components/Page'
import { UsersTab } from './Users'

export function AdminPage() {
  return (
    <Page title="Admin">
      <Tabs defaultActiveKey="1" items={items} />
    </Page>
  )
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Users',
    children: <UsersTab />,
  },
  {
    key: '2',
    label: 'Companies',
    children: 'Under construction',
  },
]
