import { CSSProperties, useMemo } from 'react'
import { Col, Form, Modal, Radio, Row } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useCompanies } from 'hooks/useCompanies'
import { IProject } from 'types/project.types'
import { useProject } from 'hooks/useProject'

interface Props {
  open: boolean
  onClose: () => void
  projectId: IProject['id']
}

export function CopyProjectModal(props: Props) {
  const { open, onClose, projectId } = props
  const [form] = useForm()
  const companyField = useWatch('company', form)

  const { data: companies } = useCompanies()
  const { copy, copyLoading } = useProject()

  const companyOptions = useMemo(
    () => companies?.filter((c) => c.id !== projectId).map((c) => ({ label: c.alias || c.name, value: c.id })),
    [companies, projectId],
  )

  const onModalClose = () => {
    form.resetFields()
    onClose()
  }

  const onProjectCopy = (values: any) => {
    const payload = { project: projectId, company: values.company }
    copy(payload, onModalClose)
  }

  return (
    <Modal
      width={500}
      title="Select Clients"
      open={open}
      onCancel={onModalClose}
      cancelText="Cancel"
      okText="Next"
      okButtonProps={{ form: 'copy-project-form', htmlType: 'submit', disabled: !companyField }}
      confirmLoading={copyLoading}
    >
      <Form form={form} onFinish={onProjectCopy} layout="vertical" id="copy-project-form">
        <Row>
          <Col span={24}>
            <Form.Item label="Client Name" name="company">
              <Radio.Group options={companyOptions} style={RadioGroupStyle} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const RadioGroupStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}
