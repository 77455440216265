import { useMatch, useNavigate } from 'react-router-dom'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Tabs, TabsProps } from 'antd'

import { Page } from 'components/Page'

import { Info } from './Info'
import { Integrations } from './Integrations'
import { Accounting } from './Accounting'
import { Payroll } from './Payroll'
import { Banks } from './Banks'

export function SettingsPage() {
  const navigate = useNavigate()

  const match = useMatch('/company/:companyId/settings/:tab')
  const selected = match?.params['tab'] || 'info'

  const onChange = (key: string) => navigate(key)

  return (
    <Page title="Company Settings">
      <Tabs activeKey={selected} items={items} onChange={onChange} />
      <Routes>
        <Route path="info" element={<Info />} />
        <Route path="integrations" element={<Integrations />} />
        <Route path="accounting" element={<Accounting />} />
        <Route path="payroll" element={<Payroll />} />
        <Route path="banks" element={<Banks />} />
        <Route path="*" element={<Navigate replace to="info" />} />
      </Routes>
      <Outlet />
    </Page>
  )
}

const items: TabsProps['items'] = [
  {
    key: 'info',
    label: 'Business Details',
  },
  {
    key: 'accounting',
    label: 'Accounting',
  },
  {
    key: 'integrations',
    label: 'E-commerce',
  },
  {
    key: 'payroll',
    label: 'Payroll',
  },
  {
    key: 'banks',
    label: 'Bank & Credit Cards',
  },
]
