import { Modal, notification, Select } from 'antd'
import { SelectProps } from 'antd/lib'
import { useEffect, useState } from 'react'
import { IAcceptedMember, RoleType } from 'types/user.types'
import { getRoleText } from 'utils/team'

type EditMemberRoleModalProps = {
  open: boolean
  onClose: () => void
  updateRoleLoading: boolean
  updateRole: ({ user, role }: { user: number; role: RoleType }) => Promise<void>
  user: IAcceptedMember | null
}

function EditMemberRoleModal(props: EditMemberRoleModalProps) {
  const { open, onClose, updateRoleLoading, updateRole, user } = props
  const [role, setRole] = useState(user?.role)

  const roleOptions: SelectProps['options'] = [
    { label: getRoleText(RoleType.Admin), value: RoleType.Admin },
    { label: getRoleText(RoleType.Member), value: RoleType.Member },
    { label: getRoleText(RoleType.ViewOnly), value: RoleType.ViewOnly },
  ]

  const onSubmit = async () => {
    const userId = user?.user.id
    if (!userId) return

    const payload = { user: userId, role: role as RoleType }

    try {
      await updateRole(payload)
      onClose()
    } catch (error) {
      notification.error({ message: 'Failed to update role' })
    }
  }

  useEffect(() => {
    setRole(user?.role)
  }, [user?.role])

  return (
    <Modal
      title="Edit Role"
      open={open}
      onCancel={onClose}
      okText="Submit"
      onOk={onSubmit}
      okButtonProps={{ loading: updateRoleLoading }}
    >
      <Select
        value={role}
        onChange={setRole}
        placeholder="Select an option"
        options={roleOptions}
        optionFilterProp="label"
        style={{ width: '100%' }}
      />
    </Modal>
  )
}

export default EditMemberRoleModal
