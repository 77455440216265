import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'

import { api } from 'utils/axios'
import { useCompanyStore } from 'store/company.store'
import type { IReview } from 'types/review.types'

interface IParams {
  id: number | null
}

interface IUpdateData {
  arg: Partial<IReview>
}

export function useReviewsAll() {
  const companyId = useCompanyStore((state) => state.id)
  const key = '/review?companyId=' + companyId
  const { data, isLoading, error } = useSWR<IReview[]>(key)

  return { data, isLoading, error }
}

export function useReview(params: IParams) {
  const { mutate: revalidate } = useSWRConfig()

  const companyId = useCompanyStore((state) => state.id)
  const id = params.id
  const key = id 
    ? `/review/${id}?companyId=${companyId}`
    : null

  const allKey = '/review?companyId=' + companyId

  const review = useSWR<IReview>(key, { revalidateOnFocus: false })
  const createMutation = useSWRMutation(allKey, createFetcher)
  const deleteMutation = useSWRMutation(allKey, deleteFetcher)
  const updateMutation = useSWRMutation(key, updateFetcher)

  const options = { onSuccess: () => revalidate(allKey) }

  return {
    data: review.data,
    create: (data: Partial<IReview>) => createMutation.trigger(data, options),
    update: (data: Partial<IReview>) => updateMutation.trigger(data, options),
    delete: (data: Partial<IReview>) => deleteMutation.trigger(data, options),
    isLoading: review.isValidating || createMutation.isMutating || deleteMutation.isMutating,
    error: review.error || createMutation.error || deleteMutation.error,
  }
}

// API

const createFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post<IReview>(url, arg)
    .then((res) => res.data)
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.put<IReview>(url, arg)
    .then((res) => res.data)
}

const deleteFetcher = (url: string, { arg }: IUpdateData) => {
  const _url = url.split('?')
  return api.delete(_url[0] + '/' + arg.id + '?' + url.split('?')[1])
    .then((res) => res.data)
}
